import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { OffcanvasService } from './services/offcanvas.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy {
  title = 'pentview-landing';
  isOffcanvasOpen: boolean = false;
  private offcanvasSubscription: Subscription;
  constructor(private offcanvasService: OffcanvasService) {
    this.offcanvasSubscription = this.offcanvasService.isOffcanvasOpen.subscribe((isOpen) => {
      this.isOffcanvasOpen = isOpen;
    });
  }

  ngOnDestroy() {
    this.offcanvasSubscription.unsubscribe();
  }

}
