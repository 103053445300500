<!-- Slide section -->
<section class="services-section1 padding-section overflow-hidden" [@fadeInDown]="fadeInDown">
	<div class="container-row d-flex row justify-content-between align-items-center">
		<h1 class="text-white title-web width-section1-title margin-bottom-80">
			{{ 'SERVICES.webtrader.section1.titleSection1' | translate }}
		</h1>
		<div class="col-12">
			<owl-carousel-o [options]="customOptions" (translated)="getPassedData($event)">
				<ng-container *ngFor="let slide of slides">
					<ng-template carouselSlide [id]="slide.title">
						<img
							(click)="
								zoomImage('image-to-hover-' + slide.title, 'modal-zoom-image-' + slide.title, 'zoom-image-' + slide.title)
							"
							[alt]="slide.alt"
							[id]="'image-to-hover-' + slide.title"
							[ngSrc]="slide.urlImage"
							[title]="slide.title"
							class="carousel-image image-to-hover" 
							width="550"
							height="310"
							priority
						/>
					</ng-template>
				</ng-container>
			</owl-carousel-o>
		</div>
		<ng-container *ngFor="let slide of slides">
			<div
				(click)="zoomOutImage('modal-zoom-image-' + slide.title, 'zoom-image-' + slide.title)"
				class="modal-zoom-image"
				[id]="'modal-zoom-image-' + slide.title">
				<img class="zoom-image p-0 p-lg-5" [id]="'zoom-image-' + slide.title" [alt]="slide.alt"
				/>
			</div>
		</ng-container>
	</div>
</section>
<!-- Form section -->
<section class="form padding-section">
	<div class="container-row d-flex justify-content-center align-items-center" data-aos="fade-down">
		<app-form [fieldsNumber]="2" [openModal]="false" [section]="nameComponent" class="mx-auto"></app-form>
	</div>
</section>
<!-- Performance section -->
<section
	class="services-section3 padding-section overflow-hidden"
	[style.height]="widthValue > 992 && heightValue"
	#videoWebTraderSection3>
	<video
		autoplay
		loop
		[muted]="true"
		playsinline
		disablePictureInPicture
		poster="../../../assets/images/services/webtrader-section3-bg.svg"
		*ngIf="isVideoVisible">
		<source src="../../../assets/images/services/services-web-trader.mp4" type="video/mp4" />
	</video>
	<div class="content-container container-row">
		<div class="row justify-content-center align-items-center m-0">
			<div class="col-lg-7 col-md-12 col-sm-12 col-12">
				<div class="row m-0">
					<div class="col-lg col-md-12 col-sm-12 col-12 px-0">
						<div class="card transparent-card me-lg-3 me-md-3 me-sm-0 me-0 mb-3">
							<div class="card-header">
								<h5>
									{{ 'SERVICES.webtrader.section3.headerCards.subtitle1' | translate }}
								</h5>
							</div>
							<div class="card-body">
								<p class="card-text">
									{{ 'SERVICES.webtrader.section3.bodyCards.textCard1-1' | translate }}
								</p>
								<p class="card-text">
									{{ 'SERVICES.webtrader.section3.bodyCards.textCard2-2' | translate }}
								</p>
							</div>
						</div>
						<div class="card transparent-card me-lg-3 me-md-3 me-sm-0 me-0">
							<div class="card-header">
								<h5>
									{{ 'SERVICES.webtrader.section3.headerCards.subtitle3' | translate }}
								</h5>
							</div>
							<div class="card-body">
								<p class="card-text">
									{{ 'SERVICES.webtrader.section3.bodyCards.textCard3-1' | translate }}
								</p>
								<p class="card-text">
									{{ 'SERVICES.webtrader.section3.bodyCards.textCard3-2' | translate }}
								</p>
							</div>
						</div>
					</div>

					<div class="col mt-lg-5 mt-md-5 mt-sm-3 mt-3 mx-lg-0 mx-0 px-0">
						<div class="card transparent-card mb-3">
							<div class="card-header">
								<h5>
									{{ 'SERVICES.webtrader.section3.headerCards.subtitle2' | translate }}
								</h5>
							</div>
							<div class="card-body">
								<p class="card-text">
									{{ 'SERVICES.webtrader.section3.bodyCards.textCard2-1' | translate }}
								</p>
								<p class="card-text">
									{{ 'SERVICES.webtrader.section3.bodyCards.textCard1-2' | translate }}
								</p>
							</div>
						</div>
						<div class="card transparent-card">
							<div class="card-header">
								<h5>
									{{ 'SERVICES.webtrader.section3.headerCards.subtitle4' | translate }}
								</h5>
							</div>
							<div class="card-body">
								<p class="card-text">
									{{ 'SERVICES.webtrader.section3.bodyCards.textCard4-1' | translate }}
								</p>
								<p class="card-text">
									{{ 'SERVICES.webtrader.section3.bodyCards.textCard4-2' | translate }}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
