import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import * as AOS from 'aos';
import { fadeInDown } from 'ng-animate';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MetaTagsService } from 'src/app/services/metaTags.service';
import { slideItem } from 'src/app/shared-components/models/shared.model';
@Component({
	selector: 'app-additional-services',
	templateUrl: './additional-services.component.html',
	styleUrls: ['./additional-services.component.css'],
	animations: [trigger('fadeInDown', [transition('* => *', useAnimation(fadeInDown))])],
})
export class AdditionalServicesComponent implements OnInit {
	@ViewChild('videoSection1') videoSection1!: ElementRef;
	public customOptions: OwlOptions = {
		autoplay: false,
		autoplayHoverPause: false,
		autoplayTimeout: 0,
		dots: false,
		loop: true,
		mouseDrag: true,
		nav: true,
		navText: [
			"<img src='../../../assets/images/about/left.svg' width='50' height='50' alt='Prev item' /> ",
			"<img src='../../../assets/images/about/right.svg' width='50' height='50' alt='Next item' />",
		],
		navSpeed: 700,
		pullDrag: false,
		responsive: {
			0: {
				autoWidth: true,
				center: true,
				items: 1,
			},
			576: {
				autoWidth: true,
				center: true,
				items: 1.25,
			},
			768: {
				autoWidth: true,
				center: true,
				items: 1.75,
			},
			992: {
				autoWidth: true,
				center: true,
				items: 2.5,
			},
		},
		rewind: true,
		touchDrag: true,
	};
	public fadeInDown: any;
	public fadeInUp: any;
	public isResponsiveServicesContainer = false;
	public isVideoVisible = false;
	public servicesSlidesItems: slideItem;
	public slideServicesItems = [
		{
			id: 0,
			title: 'titleCardTradersRoom',
			alt: 'traders room icon',
			code: 'cardTradersRoom',
			active: false,
			image: '../../../assets/images/services/img-card-1-section-2.svg',
			imageWidth: '38',
			imageHeight: '38',
			text: {
				paragraph: 'paragraphCardTradersRoom',
				item: 'included',
			},
		},
		{
			id: 1,
			title: 'titleCardAffiliatesRoom',
			alt: 'affiliates room icon',
			code: 'cardAffiliatesRoom',
			active: false,
			image: '../../../assets/images/services/img-card-2-section-2.svg',
			imageWidth: '38',
			imageHeight: '39',
			text: {
				paragraph: 'paragraphCardAffiliatesRoom',
				item: 'included',
			},
		},
		{
			id: 2,
			title: 'titleCardWebsiteDevelopment',
			alt: 'website development icon',
			code: 'cardWebsiteDevelopment',
			active: false,
			image: '../../../assets/images/services/img-card-3-section-2.svg',
			imageWidth: '37',
			imageHeight: '30',
			text: {
				paragraph: 'paragraphCardWebsiteDevelopment',
				item: 'included',
			},
		},
		{
			id: 3,
			title: 'titleCardPspConnection',
			alt: 'psp connection icon',
			code: 'cardPspConnection',
			active: false,
			image: '../../../assets/images/services/img-card-4-section-2.svg',
			imageWidth: '38',
			imageHeight: '38',
			text: {
				paragraph: 'paragraphCardPspConnection',
				item: 'included',
			},
		},
		{
			id: 4,
			title: 'titleCardIpTelephones',
			alt: 'ip telephones icon',
			code: 'cardIpTelephones',
			active: false,
			image: '../../../assets/images/services/img-card-5-section-2.svg',
			imageWidth: '38',
			imageHeight: '39',
			text: {
				paragraph: 'paragraphCardIpTelephones',
			},
		},
		{
			id: 5,
			title: 'titleCardBankAccount',
			alt: 'bank account icon',
			code: 'cardBankAccount',
			active: false,
			image: '../../../assets/images/services/img-card-6-section-2.svg',
			imageWidth: '32',
			imageHeight: '44',
			text: {
				paragraph: 'paragraphCardBankAccount',
			},
		},
		{
			id: 6,
			title: 'titleCardEducation',
			alt: 'education icon',
			code: 'cardEducation',
			active: false,
			image: '../../../assets/images/services/img-card-7-section-2.svg',
			imageWidth: '36',
			imageHeight: '41',
			text: {
				paragraph: 'paragraphCardEducation',
			},
		},
		{
			id: 7,
			title: 'titleCardOfficeSetup',
			alt: 'office setup icon',
			code: 'cardOfficeSetup',
			active: false,
			image: '../../../assets/images/services/img-card-8-section-2.svg',
			imageWidth: '20',
			imageHeight: '38',
			text: {
				paragraph: 'paragraphCardOfficeSetup',
			},
		},
		{
			id: 8,
			title: 'titleCardItSupport',
			alt: 'it support icon',
			code: 'cardItSupport',
			active: false,
			image: '../../../assets/images/services/img-card-9-section-2.svg',
			imageWidth: '37',
			imageHeight: '30',
			text: {
				paragraph: 'paragraphCardItSupport',
			},
		},
		{
			id: 9,
			title: 'titleCardLeadGeneration',
			alt: 'lead generation icon',
			code: 'cardLeadGeneration',
			active: false,
			image: '../../../assets/images/services/img-card-10-section-2.svg',
			imageWidth: '38',
			imageHeight: '39',
			text: {
				paragraph: 'paragraphCardLeadGeneration',
			},
		},
		{
			id: 10,
			title: 'titleCardCybersecuritySupport',
			alt: 'cybersecurity support icon',
			code: 'cardCybersecuritySupport',
			active: false,
			image: '../../../assets/images/services/img-card-11-section-2.svg',
			imageWidth: '34',
			imageHeight: '45',
			text: {
				paragraph: 'paragraphCardCybersecuritySupport',
			},
		},
		{
			id: 11,
			title: 'titleCardWebsiteSetup',
			alt: 'website setup icon',
			code: 'cardWebsiteSetup',
			active: false,
			image: '../../../assets/images/services/img-card-12-section-2.svg',
			imageWidth: '34',
			imageHeight: '34',
			text: {
				paragraph: 'paragraphCardWebsiteSetup',
			},
		},
		{
			id: 12,
			title: 'titleCardMarketing',
			alt: 'marketing icon',
			code: 'cardMarketing',
			active: false,
			image: '../../../assets/images/services/img-card-13-section-2.svg',
			imageWidth: '44',
			imageHeight: '43',
			text: {
				paragraph: 'paragraphCardMarketing',
			},
		},
	];

	constructor(private metaTagsService: MetaTagsService
		) {
		this.servicesSlidesItems = this.slideServicesItems[0];
		this.checkScreenSize();
	}

	ngOnInit(): void {
		AOS.init();
		const title = 'Additional services for your brokerage';
		const description = 'Pentview offers CRM and trading platform white-label solutions to optimize brokerage operations and customer management.';
		const keywords = 'CRM white label solution, IT support for uninterrupted operations, strategic lead generation, Strategic marketing for online presence, optimized brokerage management tools';
		this.metaTagsService.updateTags(title, description, keywords);
	}

	@HostListener('window:scroll') checkVideoVisibility() {
		const rect1 = this.videoSection1.nativeElement.getBoundingClientRect();
		const windowHeight = window.innerHeight;

		this.isVideoVisible = rect1.top < windowHeight && rect1.bottom >= 0;
	}

	@HostListener('window:resize', ['$event']) private onResize(event: any): void {
		this.checkScreenSize();
	}

	public checkScreenSize() {
		this.isResponsiveServicesContainer = window.innerWidth < 993;
	}

	public zoomImage() {
		const imageToHover = document.getElementById('image-to-hover') as HTMLImageElement;
		const modalZoomImage = document.getElementById('modal-zoom-image') as HTMLElement;
		const zoomImage = document.getElementById('zoom-image') as HTMLImageElement;

		if (imageToHover) {
			modalZoomImage.style.display = 'block';

			zoomImage.src = imageToHover.src;
			zoomImage.alt = imageToHover.alt;
		}
	}

	public zoomOutImage() {
		const modalZoomImage = document.getElementById('modal-zoom-image') as HTMLElement;
		const zoomImage = document.getElementById('zoom-image') as HTMLImageElement;

		zoomImage.className += ' out';
		setTimeout(() => {
			modalZoomImage.style.display = 'none';
			zoomImage.className = 'zoom-image';
		}, 400);
	}
}
