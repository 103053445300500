<!-- Section 1 - What additional services you might need? -->
<section class="additional-services-section section-1 position-relative">
	<video
		src="../../../assets/images/services/services-additional-services.mp4"
		autoplay
		loop
		[muted]="true"
		class="w-100 h-100 position-absolute"
		style="object-fit: cover"
		disablePictureInPicture
		#videoSection1></video>
	<div [@fadeInDown]="fadeInDown" class="container-section-1 padding-section position-relative">
		<article class="container-row">
			<h1 class="title-h2 text-white mx-0">
				{{ 'SERVICES.additionalServices.section1.titleSection1' | translate }}
			</h1>
			<h3 class="title-h3 text-white m-0">
				{{ 'SERVICES.additionalServices.section1.reachNewHeights' | translate }}
			</h3>
		</article>
	</div>
</section>
<!-- Section 2 - Services -->
<section class="additional-services-section section-2 padding-section position-relative">
	<div class="container-row">
		<ng-container *ngIf="!isResponsiveServicesContainer; else slideContainer">
			<div class="row row-cols-lg-3 g-4 mx-0">
				<div class="col d-flex justify-content-center" *ngFor="let card of slideServicesItems">
					<div class="card transparent-card" data-aos="example-anim2">
						<div class="card-body">
							<div class="d-flex justify-content-between align-items-center" style="margin-bottom: 20px">
								<div class="col-auto p-0">
									<h2 class="card-title m-0">
										{{ 'SHARED.slider.titlesSlider.' + card.title | translate }}
									</h2>
								</div>
								<div class="col-auto ms-3 p-0">
									<img [src]="card.image" [alt]="card.alt" width="card.imageWidth" height="card.imageHeight" style="width: 43px; height: 41px" />
								</div>
							</div>
							<p class="card-text">
								{{ 'SHARED.slider.paragraphsSlider.' + card.text.paragraph | translate }}
							</p>
						</div>
						<div class="card-footer p-0" *ngIf="card.text.item">
							<small class="text-white float-end">{{
								'SERVICES.additionalServices.section2.cards.' + card.text.item | translate
							}}</small>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
		<ng-template #slideContainer>
			<div class="row row-cols-1 g-4 m-0 w-100">
				<owl-carousel-o [options]="customOptions">
					<ng-template carouselSlide *ngFor="let slideItem of slideServicesItems">
						<div class="col">
							<div class="card transparent-card h-100">
								<div class="card-body">
									<div class="d-flex justify-content-between align-items-center" style="margin-bottom: 20px">
										<div class="col-auto p-0">
											<h2 class="card-title m-0">
												{{ 'SHARED.slider.titlesSlider.' + slideItem.title | translate }}
											</h2>
										</div>
										<div class="col-auto ms-3 p-0 align-items-center">
											<img [src]="slideItem.image" [alt]="slideItem.alt" width="card.imageWidth" height="card.imageHeight" style="width: 43px; height: 41px" />
										</div>
									</div>
									<p class="card-text">
										{{ 'SHARED.slider.paragraphsSlider.' + slideItem.text.paragraph | translate }}
									</p>
								</div>
								<div class="card-footer p-0" *ngIf="slideItem.text.item">
									<small class="text-white float-end">{{
										'SERVICES.additionalServices.section2.cards.' + slideItem.text.item | translate
									}}</small>
								</div>
							</div>
						</div>
					</ng-template>
				</owl-carousel-o>
			</div>
		</ng-template>
	</div>
</section>
<!-- Section 3 - Oriented solutions -->
<section class="additional-services-section section-3 padding-section">
	<div class="container-row d-flex row justify-content-between align-items-center">
		<div class="col-12 col-lg-4 col-text">
			<div class="col-12 text-container p-0" data-aos="fade-down">
				<h3 class="text-white text-center title-h3">
					{{ 'SERVICES.additionalServices.section3.orientedSolutions' | translate }}
				</h3>
				<p class="text-white mx-0" style="margin-bottom: 30px">
					{{ 'SERVICES.additionalServices.section3.orientedSolutionsText' | translate }}
				</p>
			</div>
			<div class="col-12 text-container mx-0 p-0" data-aos="fade-down" data-aos-delay="200">
				<h3 class="text-white text-center title-h3">
					{{ 'SERVICES.additionalServices.section3.improvedSegmentation' | translate }}
				</h3>
				<p class="text-white mx-0" style="margin-bottom: 30px">
					{{ 'SERVICES.additionalServices.section3.improvedSegmentationText' | translate }}
				</p>
			</div>
			<div class="col-12 text-container mx-0 p-0" data-aos="fade-down" data-aos-delay="300">
				<h3 class="text-white text-center title-h3">
					{{ 'SERVICES.additionalServices.section3.smartCustomerRetention' | translate }}
				</h3>
				<p class="text-white m-0">
					{{ 'SERVICES.additionalServices.section3.smartCustomerRetentionText' | translate }}
				</p>
			</div>
		</div>
		<div class="col-12 col-lg-8 margin-section-100 d-flex justify-content-center">
			<img
				(click)="zoomImage()"
				class="image-to-hover img-fluid p-0 m-0"
				ngSrc="../../../assets/images/services/img-additional-services-section-3.webp"
				alt="Client and trade base with specific information for better internal performance"
				data-aos="fade-down"
				id="image-to-hover" 
				width="836"
				height="559"
			/>
		</div>
		<div (click)="zoomOutImage()" class="modal-zoom-image" id="modal-zoom-image">
			<img class="zoom-image" id="zoom-image" alt="Client and trade base with specific information for better internal performance" />
		</div>
	</div>
</section>
