<section class="instruments-section padding-section">
	<div class="container-row d-flex row justify-content-center align-items-center">
		<h1 class="title-web text-center p-0">
			{{ 'INSTRUMENTS.titlePage' | translate }}
		</h1>
		<div class="d-flex row align-items-start m-0 p-0">
			<div class="col-12 col-lg-4 p-0 ps-lg-0">
				<!-- Dropdown for mobile view -->
				<li class="d-flex d-lg-none justify-content-center pb-5">
					<div dropdown class="dropdown btn-group">
						<button
							class="dropdown-tootgle row d-flex justify-content-between align-items-center mx-0 dropup"
							title="dropdown"
							dropdownToggle>
							{{ instrument }}
						</button>
						<ul *dropdownMenu class="dropdown-menu w-100 p-0">
							<li class="menu-item" role="tablist" aria-orientation="vertical" *ngFor="let instrument of instruments">
								<span class="dropdown-item cursor-pointer text-white w-100" (click)="changeInstrument(instrument)">
									{{ instrument }}
								</span>
							</li>
						</ul>
					</div>
				</li>
				<!-- Buttons for desktop view -->
				<div
					class="nav flex-column nav-pills me-3 d-lg-block d-none"
					id="v-pills-tab"
					role="tablist"
					aria-orientation="vertical">
					<button
						[title]="item"
						class="btn"
						(click)="changeInstrument(item)"
						*ngFor="let item of instruments"
						[ngClass]="{
							'btn-primary': item !== instrument,
							selected: item === instrument
						}">
						{{ item }}
					</button>
				</div>
			</div>
			<!-- Cards column -->
			<div class="col-12 col-lg-8 p-0 pe-lg-0">
				<div class="row justify-content-start m-0">
					<div class="col-lg-4 col-md-6 col-sm-6 col-12 mb-4 px-lg-2" *ngFor="let instrument of instrumentSelected">
						<div class="card" [@zoomIn]="{ value: zoomIn, params: { timing: 2 } }">
							<img [src]="instrument.image" [alt]="instrument.name" width="70px" />
							<div class="text-card">{{ instrument.name }}</div>
							<div class="text-card">{{ instrument.title }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
