import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment.dev';

@Component({
	selector: 'app-templates',
	templateUrl: './templates.component.html',
	styleUrls: ['./templates.component.css'],
})
export class TemplatesComponent {
	private password = environment.password;
	public viewTemplates = false;
	public passwordForm = new FormGroup({
		password: new FormControl('', [Validators.required, Validators.pattern(this.password)]),
	});
	public invalidFields = false;

	constructor() {}

	access(): void {
		if (this.passwordForm.invalid) {
			this.invalidFields = true;
			return;
		}
		this.invalidFields = false;
		this.viewTemplates = true;
	}
}
