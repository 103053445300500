import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Client } from "../../models/client";
import { RegisterFormService } from "../../services/register-form.service";
import { DialogErrorComponent } from "../dialog-error/dialog-error.component";

declare var fbq: any;

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.css"],
})
export class FormComponent implements OnInit, OnDestroy {
  @ViewChild("messageModal") messageModal: TemplateRef<any>;
  @Input() fieldsNumber: number = 4;
  @Input() section: string = "contacts";
  @Input() openModal: boolean = false;
  private dialogErrorModalRef: BsModalRef;
  private initialValuesRegisterForm: FormGroup;

  //public openModal: boolean;
  public registerForm: FormGroup;
  public registerFormDuo: FormGroup;
  public invalidField = false;
  public isSubmitting = false;
  public backMessage = "";
  /* phone number variables */
  public phoneCountryAbbreviation = "";
  public phoneCode = 0;
  public flagFirstName: boolean;
  public flagLastName: boolean;
  public flagEmail: boolean;
  public flagPhone: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private registerFormService: RegisterFormService,
    public registerFormModalRef: BsModalRef,
    public messageModalRef: BsModalRef
  ) {
    this.openModal = false;
    this.flagFirstName = false;
    this.flagLastName = false;
    this.flagEmail = false;
    this.flagPhone = false;
    this.initialValuesRegisterForm = this.registerForm?.value;
    this.registerForm = this.formBuilder.group({
      firstName: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9-ZñÑáéíóúÁÉÍÓÚ ]*$"),
        ],
      ],
      lastName: [
        "",
        [Validators.required, Validators.pattern("^[a-zA-ZñÑáéíóúÁÉÍÓÚ ]*$")],
      ],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],
      phone: [
        "",
        [
          Validators.required,
          Validators.pattern(/^([0-9])*$/),
          Validators.maxLength(16),
        ],
      ],
    });

    this.registerFormDuo = this.formBuilder.group({
      fullname: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9-ZñÑáéíóúÁÉÍÓÚ ]*$"),
        ],
      ],
      celphone: [
        "",
        [
          Validators.required,
          Validators.pattern(/^([0-9])*$/),
          Validators.maxLength(16),
        ],
      ],
    });
  }

  public get form(): { [key: string]: AbstractControl } {
    return this.registerForm.controls;
  }
  public get formDuo(): { [key: string]: AbstractControl } {
    return this.registerFormDuo.controls;
  }

  ngOnInit(): void {
    if (this.openModal) {
      this.registerForm.get("lastName")?.setValidators(null);
      this.registerForm.get("lastName")?.updateValueAndValidity();
    }
  }

  telInputObject(obj: any) {
    obj.setCountry("ua");
    this.phoneCountryAbbreviation = obj.selectedCountryData?.iso2;
    this.phoneCode = obj.selectedCountryData?.dialCode;
  }

  onCountryChange(event: any) {
    this.phoneCountryAbbreviation = event.iso2;
    this.phoneCode = event.dialCode;
  }

  // Metodo para validar la entrada  sólo números y teclas de control
  validateNumber(event: KeyboardEvent): void {
    const key = event.key;
    if (
      !/^\d$/.test(key) &&
      key !== "Backspace" &&
      key !== "ArrowLeft" &&
      key !== "ArrowRight" &&
      key !== "Delete"
    ) {
      event.preventDefault();
    }
  }

  onKey(nameLabel: string) {
    if (nameLabel === "firstName") {
      this.flagFirstName = true;
    }
    if (nameLabel === "lastName") {
      this.flagLastName = true;
    }
    if (nameLabel === "email") {
      this.flagEmail = true;
    }
    if (nameLabel === "phone") {
      this.flagPhone = true;
    }
  }
  public postClient(client: Client) {
    this.registerFormService.registerClient(client).subscribe(
      (res) => {
        this.backMessage = res.message;
        this.messageModalRef = this.modalService.show(this.messageModal);
        this.registerFormModalRef?.hide();
        this.isSubmitting = false;
        this.invalidField = false;
        this.registerForm.reset(this.initialValuesRegisterForm);
        this.registerFormDuo.reset(this.initialValuesRegisterForm);
        this.openModal = false;
        this.flagFirstName = false;
        this.flagLastName = false;
        this.flagEmail = false;
        this.flagPhone = false;
        this.trackCompleteRegistration(client);
      },
      (err) => {
        this.isSubmitting = false;
        const initialState = { message: err.error.message };
        this.dialogErrorModalRef = this.modalService.show(
          DialogErrorComponent,
          { initialState }
        );
      }
    );
  }

  public trackCompleteRegistration(data: Client) {
    if (typeof fbq === "function") {
      fbq("track", "CompleteRegistration", data);
    }
  }
  public listenText(event: Event, controlName: string, formName: string) {
    const inputElement = event.target as HTMLInputElement;
    const originalValue = inputElement.value;
    const trimmedValue = inputElement.value.trim();
    const cleanedValue = trimmedValue.replace(/\s{2,}/g, " ");
    if (originalValue !== cleanedValue) {
      // Actualizamos el valor en el campo
      inputElement.value = cleanedValue;

      // Actualizamos el valor del control en el formulario correspondiente
      const form =
        formName === "registerForm" ? this.registerForm : this.registerFormDuo;
      const control = form.get(controlName);
      if (control) {
        control.setValue(cleanedValue);
        control.updateValueAndValidity();
      }
    }
  }

  onEmailInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const cleanedValue = inputElement.value.replace(/\s/g, ""); // Eliminar espacios en blanco
    inputElement.value = cleanedValue; // Actualizar el valor del campo
    const control = this.registerForm.get("email");
    // También puedes actualizar el valor del control en el formulario

    if (control !== null) {
      control.setValue(cleanedValue);
    }
  }

  public registerClientDuo(): void {
    if (this.registerFormDuo.invalid) {
      this.invalidField = true;
      return;
    }
    const auxClient: Client = {
      firstName: this.registerFormDuo.get("fullname")?.value,
      phoneCountryAbbreviation: this.phoneCountryAbbreviation?.toUpperCase(),
      phoneCode: "+" + this.phoneCode?.toString(),
      phone: this.registerFormDuo.get("celphone")?.value,
    };
    this.postClient(auxClient);
  }

  public registerClient(): void {
    const SpacesRegularExpression = /(\s{2,})/g;
    if (this.section === "crm") {
      this.registerForm.get("lastName")?.setValidators(null);
      this.registerForm.get("email")?.setValidators(null);
      this.registerForm.get("lastName")?.updateValueAndValidity();
      this.registerForm.get("email")?.updateValueAndValidity();
    }

    if (this.registerForm.invalid) {
      this.invalidField = true;
      return;
    }

    this.isSubmitting = true;

    const client: Client = {
      firstName: this.registerForm
        .get("firstName")
        ?.value.trim()
        .replace(SpacesRegularExpression, " "),
      lastName: this.registerForm
        .get("lastName")
        ?.value.trim()
        .replace(SpacesRegularExpression, " "),
      email: this.registerForm.get("email")?.value,
      phoneCountryAbbreviation: this.phoneCountryAbbreviation?.toUpperCase(),
      phoneCode: "+" + this.phoneCode?.toString(),
      phone: this.registerForm.get("phone")?.value,
    };

    if (this.openModal) {
      delete client.lastName;
    }
    this.postClient(client);
  }

  public closeMessageModal(): void {
    this.messageModalRef?.hide();
    this.registerForm.reset();
  }

  ngOnDestroy(): void {
    this.registerFormModalRef?.hide();
  }
}
