import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { fadeInDown } from 'ng-animate';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { FormComponent } from 'src/app/shared-components/form/form.component';
import { MetaTagsService } from '../../services/metaTags.service';
@Component({
	selector: 'app-about-us',
	templateUrl: './about-us.component.html',
	styleUrls: ['./about-us.component.css'],
	animations: [trigger('fadeInDown', [transition('* => *', useAnimation(fadeInDown))])],
})
export class AboutUsComponent implements OnInit, OnDestroy {
	@ViewChild('videoAbout') videoAbout!: ElementRef;
	private bsModalRef: BsModalRef;
	fadeInDown: any;
	public openModal = false;
	public isVideoVisible = true;
	public customOptions: OwlOptions = {
		dots: false,
		loop: false,
		margin: 30,
		mouseDrag: true,
		nav: true,
		navSpeed: 700,
		navText: ["<img src='../../../assets/images/about/left.svg'> ", "<img src='../../../assets/images/about/right.svg'>"],
		pullDrag: false,
		responsive: {
			0: {
				autoWidth: false,
				center: true,
				items: 1,
				nav: false,
			},
			400: {
				autoWidth: true,
				items: 2.1,
				nav: false,
			},
			740: {
				autoWidth: true,
				items: 3,
				nav: false,
			},
			940: {
				autoWidth: false,
				items: 3,
			},
		},
		touchDrag: true,
	};
	public customOptions2: OwlOptions = {
		dots: false,
		loop: false,
		mouseDrag: true,
		nav: true,
		navSpeed: 700,
		navText: ["<img src='../../../assets/images/about/left.svg'> ", "<img src='../../../assets/images/about/right.svg'>"],
		pullDrag: false,
		responsive: {
			0: {
				autoWidth: false,
				center: true,
				items: 1,
				margin: 40,
			},
			576: {
				autoWidth: true,
				items: 1.5,
				loop: true,
				margin: 50,
			},
			720: {
				autoWidth: true,
				items: 1.5,
				loop: true,
				margin: 50,
			},
			940: {
				autoWidth: true,
				items: 3,
				margin: 20,
			},
		},
		touchDrag: true,
	};
	public carouselItems = Array(9).fill(null); // lang: ABOUT.section2.pentview.length

	constructor(
		private modalService: BsModalService,
		private metaTagsService: MetaTagsService
		) {}

	ngOnInit(): void {
		const title = 'About us';
		const description = 'Fueled by passion, united in teamwork, and grounded in trust – these values guide us to provide top-notch service at the best prices.';
		const keywords = 'Technological solutions';
		this.metaTagsService.updateTags(title, description, keywords);
	}

	public openRegisterFormModal(): void {
		this.openModal = true;
		const initialState = {
			initialState: {
				openModal: this.openModal,
			},
		};
		this.bsModalRef = this.modalService.show(FormComponent, initialState);
	}

	ngOnDestroy(): void {
		this.bsModalRef?.hide();
	}

	@HostListener('window:scroll') checkVideoVisibility() {
		const rect1 = this.videoAbout.nativeElement.getBoundingClientRect();
		const windowHeight = window.innerHeight;
		this.isVideoVisible = rect1.top < windowHeight && rect1.bottom >= 0;
	}
}
