<section class="contacts-section padding-section">
  <div class="container-row d-flex row justify-content-between align-items-center">
    <div [@fadeInDown]="fadeInDown" class="col-12 col-lg-6 p-0">
      <h1 class="title-web order-lg-1 order-md-1 subtitle1-contact m-0">{{ "CONTACTS.titleContact" | translate }}</h1>

      <h2 class="title-h3 pt-3 order-lg-2 order-md-3 order-sn-2 subtitle1-contact m-0">
        {{ "CONTACTS.subtitle1" | translate }}
      </h2>

      <p class="pt-3 order-lg-3 order-md-2 order-sm-3 m-0">{{ "CONTACTS.subtitle2" | translate }}</p>
    </div>
    <div [@fadeInDown]="fadeInDown" class="col-6 d-none d-lg-block p-0" #videoContact>
      <video
        [muted]="true"
        autoplay
        loop
        *ngIf="isVideoVisible"
        class="float-end"
        alt="Japanese candles available in the charts of our WebTrader"
        disablePictureInPicture
      >
        <source src="../../../assets/images/contact/Contact-us-Animation.mp4" />
      </video>
    </div>
    <div class="form col-12 d-flex justify-content-center align-items-center p-0">
      <app-form [fieldsNumber]="4" [section]="nameComponent"></app-form>
    </div>
    <div class="form col-12 d-block d-lg-none d-flex justify-content-center align-items-center" #videoContact>
      <video
        [muted]="true"
        autoplay
        loop
        *ngIf="isVideoVisible"
        alt="Japanese candles available in the charts of our WebTrader"
        disablePictureInPicture
        poster="../../../assets/images/contact/Graph.webp"
      >
        <source src="../../../assets/images/contact/Contact-us-Animation.mp4" />
      </video>
    </div>
  </div>
</section>
