import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { OffcanvasService } from 'src/app/services/offcanvas.service';
import { environment } from 'src/environments/environment.dev';
import { FormComponent } from '../form/form.component';
import { languagesServiceList } from '../utils/languages';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {
	private bsModalRef: BsModalRef;
	public openModal = false;
	public languagesList = languagesServiceList;
	public languageTypeList: {
		idLanguage: number;
		name: string;
		code: string;
		flagUrl: string;
	}[];
	public selectedLanguage: {
		idLanguage: number;
		name: string;
		code: string;
		flagUrl: string;
	};
	public language: {
		idLanguage: number;
		name: string;
		code: string;
		flagUrl: string;
	};

	public pages: {
		route: string;
		active: boolean;
	}[] = [];

	public isNavbarCollapsed = true;

	constructor(private modalService: BsModalService, private translateService: TranslateService, private offcanvasService: OffcanvasService) {}

	ngOnInit(): void {
		this.getLanguage();
	}
	isMobile(): boolean {
		return window.innerWidth < 992; // Verifica si el ancho de la ventana es menor que 992 píxeles
	}
	toggleOffcanvas() {
		this.offcanvasService.toggleOffcanvas();
	}
	public changeState(route: string): void {
		this.pages = this.pages.map((page) => {
			if (route === page.route) {
				return { ...page, active: true };
			} else {
				return { ...page, active: false };
			}
		});
	}

	public showToggleNavigation(): void {
		this.isNavbarCollapsed = !this.isNavbarCollapsed;
	}

	public openRegisterFormModal(): void {
		this.openModal = true;
		const initialState = {
			initialState: {
				openModal: this.openModal,
			},
		};
		this.bsModalRef = this.modalService.show(FormComponent, initialState);
	}

	public getLanguage(): void {
		this.languageTypeList = languagesServiceList;
		this.selectedLanguage = environment.languages.includes(localStorage.getItem('language')!)
			? this.languagesList.filter((lang) => lang.code === localStorage.getItem('language'))[0]
			: languagesServiceList[1];
	}

	public changeLanguage(language: { idLanguage: number; name: string; code: string; flagUrl: string }): void {
		this.selectedLanguage = language;
		this.language = {
			idLanguage: this.selectedLanguage.idLanguage,
			name: this.selectedLanguage.name,
			code: this.selectedLanguage.code,
			flagUrl: this.selectedLanguage.flagUrl,
		};
		environment.languages.includes(this.language.code) ? language.code : 'en';
		this.translateService.use(language.code);
		localStorage.setItem('language', language.code);
	}

	ngOnDestroy(): void {
		this.bsModalRef.hide();
	}
}
