<!-- Section 1 - this is pentview -->
<section class="section-1 padding-section">
	<div class="container-row row justify-content-between m-0">
		<div class="col-12 col-lg-6 order-lg-1 order-sm-1 p-0">
			<h1 class="title-web m-0" [@fadeInDown]="fadeInDown">
				{{ 'ABOUT.section1.titleSection1' | translate }}
			</h1>
		</div>
		<div class="col-12 col-lg-6 order-lg-2 order-3 px-0 pt-5 p-lg-0" [@fadeInDown]="fadeInDown" #videoAbout>
			<video [muted]="true" autoplay loop *ngIf="isVideoVisible" alt="Charts with specific details for data exploration" width="570" height="208" disablePictureInPicture>
				<source src="../../../assets/images/about/About-Us-Animation.mp4" />
			</video>
		</div>
		<div class="row justify-content-between align-items-start margin-top-120 order-2 order-lg-3 m-0 px-0"
			[@fadeInDown]="fadeInDown">
			<div class="col-md-4 col-lg-4 text-center w-25">
				<img ngSrc="../../../assets/images/about/Icon.svg" alt="like icon" width="52" height="52" />
				<h2 class="my-3 title">{{ 'ABOUT.section1.cardtitle1' | translate }}</h2>
				<p class="px-lg-4 px-sm-3 px-0 text-start">
					{{ 'ABOUT.section1.textcard1' | translate }}
				</p>
			</div>
			<div class="col-md-4 col-lg-4 text-center w-25">
				<img ngSrc="../../../assets/images/about/Icon1.svg" alt="team icon" width="52" height="52" />
				<h2 class="my-3 title">{{ 'ABOUT.section1.cardtitle2' | translate }}</h2>
				<p class="px-lg-4 px-sm-3 px-0 text-start">
					{{ 'ABOUT.section1.textcard2' | translate }}
				</p>
			</div>
			<div class="col-md-4 col-lg-4 text-center w-25">
				<img ngSrc="../../../assets/images/about/Icon2.svg" alt="handshake icon" width="60" height="60" />
				<h2 class="my-3 title">{{ 'ABOUT.section1.cardtitle3' | translate }}</h2>
				<p class="px-lg-4 px-sm-3 px-0 text-start">
					{{ 'ABOUT.section1.textcard3' | translate }}
				</p>
			</div>
		</div>
	</div>
</section>
<!-- Section 2 - pentview comparison-->
<section class="section-2 padding-section">
	<div class="container-row m-0">
		<div class="row row-cols-1 justify-content-between align-items-center g-4 m-0">
			<!-- Col titles -->
			<div class="col-12 col-md-3 m-0 p-0">
				<p class="text-32 pentview-text m-0 px-0">
					{{ 'ABOUT.section2.pentviewTitle' | translate }}
				</p>
				<p class="text-32 other-text m-0 px-0">
					{{ 'ABOUT.section2.otherTitle' | translate }}
				</p>
			</div>
			<!-- Col carousel -->
			<div class="col-12 col-md-9 m-0 p-0">
				<owl-carousel-o [options]="customOptions" class="p-0 m-0">
					<ng-template carouselSlide *ngFor="let item of carouselItems; index as i">
						<div class="col mx-0 my-5 px-0 py-3 p-lg-0">
							<div class="card card-pentview">
								<img class="icon-approval position-absolute"
									ngSrc="../../../assets/images/about/approval.svg" alt="Approval icon" width="33"
									height="33" />
								<div class="card-body d-flex justify-content-center align-items-center p-0">
									<p class="card-text text-center">{{ 'ABOUT.section2.pentview.card' + (i + 1) |
										translate }}</p>
								</div>
							</div>
						</div>
						<div class="col px-0 pt-4 pb-0 p-lg-0">
							<div class="card card-danger">
								<img class="icon-reject position-absolute"
									ngSrc="../../../assets/images/about/reject.svg" alt="Reject icon" width="33"
									height="33" />
								<div class="card-body d-flex justify-content-center align-items-center p-0">
									<p class="card-text text-center">{{ 'ABOUT.section2.others.card' + (i + 1) |
										translate }}</p>
								</div>
							</div>
						</div>
					</ng-template>
				</owl-carousel-o>
			</div>
		</div>
	</div>
</section>
<!-- Section 3 - our news-->
<section class="section-3 padding-section">
	<div class="container-row row justify-content-between m-0">
		<div class="col-12 col-lg-6 p-0">
			<div class="title-web text-light-blue m-0 p-0">
				{{ 'ABOUT.section3.ourNews' | translate }}
			</div>
			<h3 class="title-h3 mx-0 mb-0 p-0">
				{{ 'ABOUT.section3.subtitle' | translate }}
			</h3>
		</div>
		<div class="col-12 col-lg-6 d-lg-flex justify-content-end align-items-end p-0 pt-lg-0 pt-2">
			<a href="/contacts-us"><button title="Contact us" class="get-trial-button">{{ 'TEXTBUTTONS.plan' | translate }}</button></a>
		</div>
		<div class="margin-top-80 col-12 p-0">
			<owl-carousel-o [options]="customOptions2" class="carousel">
				<!-- B2B EXPO Cyprus -->
				<ng-template carouselSlide>
					<div class="card">
						<div class="card-body">
							<h4 class="card-title">
								{{ 'ABOUT.section3.cardTitle3' | translate }}
							</h4>
							<span class="date-text">{{ 'ABOUT.section3.cardDate3' | translate }}</span>
							<p class="card-text m-0">
								{{ 'ABOUT.section3.cardText3-1' | translate }}
							</p>
							<p class="card-text m-0 pt-2">
								{{ 'ABOUT.section3.cardText3-2' | translate }}
							</p>
						</div>
						<img ngSrc="../../../assets/images/about/img-b2b-expo-cyprus.svg" class="img-fluid"
						alt="Expositions of technologies similar to those we provide to our partners
						" width="440" height="90" />
					</div>
				</ng-template>
				<!-- Affiliate World Barcelona -->
				<ng-template carouselSlide>
					<div class="card">
						<div class="card-body">
							<h4 class="card-title text-white">
								{{ 'ABOUT.section3.cardTitle1' | translate }}
							</h4>
							<span class="date-text">{{ 'ABOUT.section3.cardDate1' | translate }}</span>
							<p class="card-text m-0">
								{{ 'ABOUT.section3.cardText1-1' | translate }}
							</p>
							<p class="card-text m-0 pt-2">
								{{ 'ABOUT.section3.cardText1-2' | translate }}
							</p>
						</div>
						<img ngSrc="../../../assets/images/about/image1.svg" class="img-fluid"
						alt="Expositions on new technologies that we attend" width="440" height="90" />
					</div>
				</ng-template>
				<!-- IFX EXPO Thailand -->
				<ng-template carouselSlide>
					<div class="card">
						<div class="card-body">
							<h4 class="card-title text-white">
								{{ 'ABOUT.section3.cardTitle2' | translate }}
							</h4>
							<span class="date-text">{{ 'ABOUT.section3.cardDate2' | translate }}</span>
							<p class="card-text m-0">
								{{ 'ABOUT.section3.cardText2-1' | translate }}
							</p>
							<p class="card-text m-0 pt-2">
								{{ 'ABOUT.section3.cardText2-2' | translate }}
							</p>
						</div>
						<img ngSrc="../../../assets/images/about/image2.svg" class="img-fluid" alt="Expositions of technologies similar to those we provide to our partners"
							width="440" height="90" />
					</div>
				</ng-template>
			</owl-carousel-o>
		</div>
	</div>
</section>