import { Component } from '@angular/core';
import { Instrument } from 'src/app/models/instrument';
import { trigger, transition, useAnimation } from '@angular/animations';
import { zoomIn } from 'ng-animate';
@Component({
  selector: 'app-instruments',
  templateUrl: './instruments.component.html',
  styleUrls: ['./instruments.component.css'],
  animations: [
    trigger('zoomIn', [transition('* => *', useAnimation(zoomIn))])
  ],
})
export class InstrumentsComponent {
  zoomIn: any
  instrument: string;
  instruments: string[] = ['Forex (19)', 'Crypto (8)', 'Stocks (22)', 'Commodities (4)', 'Etfs (4)', 'Indices (15)'];
  instrumentSelected: Instrument[] 
  forexArr: Instrument[] = [
    {
      image: '../../../assets/images/instruments/AUD USD.svg',
      name: 'AUD/USD',
      title: '(Australian Dollar/US Dollar)'
    },
    {
      image: '../../../assets/images/instruments/CHF JPY.svg',
      name: 'CHF/JPY',
      title: '(Euro/Japanese Yen)'
    },
    {
      image: '../../../assets/images/instruments/EUR JPY.svg',
      name: 'EUR/JPY',
      title: '(Swiss Franc/Japanese Yen)'
    },
    {
      image: '../../../assets/images/instruments/GBP EUR.svg',
      name: 'GBP/EUR',
      title: '(British Pound/Euro)'
    },
    {
      image: '../../../assets/images/instruments/GBP CAD.svg',
      name: 'GBP/CAD',
      title: '(British Pound/Canadian Dollar)'
    },
    {
      image: '../../../assets/images/instruments/USD GBP.svg',
      name: 'USD/GBP',
      title: '(US Dollar/British Pound)'
    },
    {
      image: '../../../assets/images/instruments/USD MXN.svg',
      name: 'MXN/USD',
      title: '(Mexican Peso/US Dollar)'
    },
    {
      image: '../../../assets/images/instruments/MXN USD.svg',
      name: 'MXN/USD',
      title: '(Mexican Peso/US Dollar)'
    },
    {
      image: '../../../assets/images/instruments/AUD JPY.svg',
      name: 'AUD/JPY ',
      title: '(Australian Dollar/Japanese Yen)'
    },
    {
      image: '../../../assets/images/instruments/EUR TRY.svg',
      name: 'EUR/TRY ',
      title: '(Euro/Turkish Lira)'
    },
    {
      image: '../../../assets/images/instruments/CAD NOK.svg',
      name: 'CAD/NOK ',
      title: '(Canadian Dollar/Norwegian Krone)'
    },
    {
      image: '../../../assets/images/instruments/EUR USD.svg',
      name: 'EUR/USD ',
      title: '(Euro/US Dollar)'
    },
    {
      image: '../../../assets/images/instruments/EUR GBP.svg',
      name: 'EUR/GBP',
      title: '(Euro/British Pound)'
    },
    {
      image: '../../../assets/images/instruments/GBP JPY.svg',
      name: 'GBP/JPY',
      title: '(British Pound/Japanese Yen)'
    },
    {
      image: '../../../assets/images/instruments/USD JPY.svg',
      name: 'USD/JPY',
      title: '(US Dollar/Japanese Yen)'
    },
    {
      image: '../../../assets/images/instruments/USD EUR.svg',
      name: 'USD/EUR',
      title: '(US Dollar/Euro)'
    },
    {
      image: '../../../assets/images/instruments/RUB USD.svg',
      name: 'RUB/USD ',
      title: '(Russian Ruble/US Dollar)'
    },
    {
      image: '../../../assets/images/instruments/USD RUB.svg',
      name: 'USD/RUB ',
      title: '(US Dollar/Russian Ruble)'
    },
    {
      image: '../../../assets/images/instruments/USD TRY.svg',
      name: 'USD/TRY ',
      title: '(US Dollar/Turkish Lira)'
    },
    
  ];
  cryptoArr: Instrument[] = [
    {
      image: '../../../assets/images/instruments/BTCUSD.svg',
      name: 'BTCUSD',
      title: '(Bitcoin/US Dollar)'
    },
    {
      image: '../../../assets/images/instruments/ETHUSD.svg',
      name: 'ETHUSD',
      title: '(Ethereum/US Dollar)'
    },
    {
      image: '../../../assets/images/instruments/LTCUSD.svg',
      name: 'LTCUSD',
      title: '(Litecoin/US Dollar)'
    },
    {
      image: '../../../assets/images/instruments/BNBUSD.svg',
      name: 'BNBUSD',
      title: '(Binance Coin/US Dollar)'
    },
    {
      image: '../../../assets/images/instruments/SOLUSD.svg',
      name: 'SOLUSD',
      title: '(Solana/US Dollar)'
    },
    {
      image: '../../../assets/images/instruments/AXSUSD.svg',
      name: 'AXSUSD',
      title: '(Axie Infinity Shards/US Dollar)'
    },
    {
      image: '../../../assets/images/instruments/XRPUSD.svg',
      name: 'XRPUSD',
      title: '(Ripple/US Dollar)'
    },
    {
      image: '../../../assets/images/instruments/DASHUSD.svg',
      name: 'DASHUSD',
      title: '(Dash/US Dollar)'
    },
  ];
  stocksArr: Instrument[] = [
    {
      image: '../../../assets/images/instruments/AAPL.svg',
      name: 'AAPL',
      title: '(Apple Inc.)'
    },
    {
      image: '../../../assets/images/instruments/NFLX.svg',
      name: 'NFLX',
      title: '(Netflix Inc.)'
    },
    {
      image: '../../../assets/images/instruments/AMZN.svg',
      name: 'AMZN',
      title: '(Amazon.com Inc.)'
    },
    {
      image: '../../../assets/images/instruments/META.svg',
      name: 'META',
      title: '(Meta Platforms Inc. - formerly known as Facebook Inc.)'
    },
    {
      image: '../../../assets/images/instruments/TSLA.svg',
      name: 'TSLA',
      title: '(Tesla Inc.)'
    },
    {
      image: '../../../assets/images/instruments/MSFT.svg',
      name: 'MSFT',
      title: '(Microsoft Corporation)'
    },
    {
      image: '../../../assets/images/instruments/GOOGL.svg',
      name: 'GOOGL',
      title: '(Alphabet Inc. - Class A shares)'
    },
    {
      image: '../../../assets/images/instruments/PFE.svg',
      name: 'PFE',
      title: '(Pfizer Inc.)'
    },
    {
      image: '../../../assets/images/instruments/COIN.svg',
      name: 'COIN',
      title: '(Coinbase Global Inc.)'
    },
    {
      image: '../../../assets/images/instruments/SAP.svg',
      name: 'SAP',
      title: '(SAP SE)'
    },
    {
      image: '../../../assets/images/instruments/ABNB.svg',
      name: 'ABNB',
      title: '(Airbnb Inc.)'
    },
    {
      image: '../../../assets/images/instruments/LMT.svg',
      name: 'LMT',
      title: '(Lockheed Martin Corporation)'
    },
    
    {
      image: '../../../assets/images/instruments/BRKA.svg',
      name: 'BRKA',
      title: '(Lockheed Martin Corporation)'
    },
    {
      image: '../../../assets/images/instruments/NVDA.svg',
      name: 'NVDA',
      title: '(NVIDIA Corporation)'
    },
    {
      image: '../../../assets/images/instruments/BA.svg',
      name: 'BA',
      title: '(The Boeing Company)'
    },
    {
      image: '../../../assets/images/instruments/SPGI.svg',
      name: 'SPGI',
      title: '(S&P Global Inc.)'
    },
    {
      image: '../../../assets/images/instruments/JPM.svg',
      name: 'JPM',
      title: '(JPMorgan Chase & Co.)'
    },
    {
      image: '../../../assets/images/instruments/PYPL.svg',
      name: 'PYPL',
      title: '(PayPal Holdings Inc.)'
    },
    {
      image: '../../../assets/images/instruments/UBER.svg',
      name: 'UBER',
      title: '(Uber Technologies Inc.)'
    },
    {
      image: '../../../assets/images/instruments/PLD.svg',
      name: 'PLD',
      title: '(Prologis Inc.)'
    },
    {
      image: '../../../assets/images/instruments/AMT.svg',
      name: 'AMT',
      title: '(American Tower Corporation)'
    },
    {
      image: '../../../assets/images/instruments/CSGP.svg',
      name: 'CSGP',
      title: '(CoStar Group Inc.)'
    },

  ];
  commoditiesArr: Instrument[] = [
    {
      image: '../../../assets/images/instruments/XPDUSD.svg',
      name: 'XPDUSD',
      title: '(Palladium/US Dollar)'
    },
    {
      image: '../../../assets/images/instruments/XPTUSD.svg',
      name: 'XPTUSD',
      title: '(Platinum/US Dollar)'
    },
    {
      image: '../../../assets/images/instruments/XAUUSD.svg',
      name: 'XAUUSD',
      title: '(Gold/US Dollar)'
    },
    {
      image: '../../../assets/images/instruments/XAGUSD.svg',
      name: 'XAGUSD',
      title: '(Silver/US Dollar)'
    },
    
  ];
  etfsArr: Instrument[] = [
    {
      image: '../../../assets/images/instruments/SPY.svg',
      name: 'SPY',
      title: '(SPDR S&P 500 ETF Trust)'
    },
    {
      image: '../../../assets/images/instruments/SQQQ.svg',
      name: 'SQQQ',
      title: '(ProShares UltraPro Short QQQ ETF)'
    },
    {
      image: '../../../assets/images/instruments/DIA.svg',
      name: 'DIA',
      title: '(SPDR Dow Jones Industrial Average ETF Trust)'
    },
    {
      image: '../../../assets/images/instruments/XOP.svg',
      name: 'XOP',
      title: '(SPDR S&P Oil & Gas Exploration & Production ETF)'
    },

  ];
  indicesArr: Instrument[] = [
    {
      image: '../../../assets/images/instruments/NDX.svg',
      name: 'NDX',
      title: '(Nasdaq 100 Index)'
    },
    {
      image: '../../../assets/images/instruments/SPX.svg',
      name: 'SPX',
      title: '(S&P 500 Index)'
    },
    {
      image: '../../../assets/images/instruments/VIX.svg',
      name: 'VIX',
      title: '(CBOE Volatility Index)'
    },
    {
      image: '../../../assets/images/instruments/RUT.svg',
      name: 'RUT',
      title: '(Russell 2000 Index)'
    },
    {
      image: '../../../assets/images/instruments/RUA.svg',
      name: 'RUA',
      title: '(Russell 3000 Index)'
    },
    {
      image: '../../../assets/images/instruments/DJL.svg',
      name: 'DJI',
      title: '(Dow Jones Industrial Average)'
    },
    {
      image: '../../../assets/images/instruments/DJT.svg',
      name: 'DJT',
      title: '(Dow Jones Transportation Average)'
    },
    {
      image: '../../../assets/images/instruments/IXIC.svg',
      name: 'IXIC',
      title: '(Nasdaq Composite Index)'
    },
    {
      image: '../../../assets/images/instruments/FTSE.svg',
      name: 'FTSE',
      title: '(Financial Times Stock Exchange)'
    },
    {
      image: '../../../assets/images/instruments/CA12S.svg',
      name: 'CA12S',
      title: '(CAC 40 Index)'
    },
    {
      image: '../../../assets/images/instruments/IBEX.svg',
      name: 'IBEX',
      title: '(IBEX 35 Index)'
    },
    {
      image: '../../../assets/images/instruments/HSI.svg',
      name: 'HSI',
      title: '(Hang Seng Index)'
    },
    {
      image: '../../../assets/images/instruments/N225.svg',
      name: 'N225',
      title: '(Nikkei 225 Index)'
    },
    {
      image: '../../../assets/images/instruments/TECDAX.svg',
      name: 'TECDAX',
      title: '(TecDAX Index)'
    },
    {
      image: '../../../assets/images/instruments/GDAX.svg',
      name: 'GDAX',
      title: '(DAX Index)'
    },
  ];


  constructor() {
    this.instrument = this.instruments[0];
    this.instrumentSelected = this.forexArr
  }

  ngOnInit() {
  }

  changeInstrument(instrument: string) {
    this.instrument = instrument
    if (this.instrument === this.instruments[0]) {
      this.instrumentSelected = this.forexArr;
    }
    if (this.instrument === this.instruments[1]) {
      this.instrumentSelected = this.cryptoArr;
    }
    if (this.instrument === this.instruments[2]) {
      this.instrumentSelected = this.stocksArr;
    }
    if (this.instrument === this.instruments[3]) {
      this.instrumentSelected = this.commoditiesArr;
    }
    if (this.instrument === this.instruments[4]) {
      this.instrumentSelected = this.etfsArr;
    }
    if (this.instrument === this.instruments[5]) {
      this.instrumentSelected = this.indicesArr;
    }

  }
}
