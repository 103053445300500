import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-presentation',
  template: `
    <div style="position: relative; height:715px;">
      <div class="pdf-container">
        <pdf-viewer
          [src]="'../../../assets/docs/Pentview-presentation.pdf'"
          [rotation]="0"
          [original-size]="false"
          [show-all]="true"
          [fit-to-page]="true"
          [zoom]="1"
          [zoom-scale]="'page-fit'"
          [stick-to-page]="false"
          [render-text]="true"
          [external-link-target]="'blank'"
          [autoresize]="true"
          [show-borders]="false"
          style="width: 100%; height: 80vh; display: block;position: relative; margin: auto !important"
        ></pdf-viewer>
      </div>
      <button
        style="position: absolute; right: 2rem; top: 1rem; padding: 1rem; color: var(--secondary-text) background-color: var(--secondary-text)"
        (click)="downloadPdf()"
      >
        <em class="fa-solid fa-download me-2"></em>Download PDF
      </button>
    </div>
  `,
  styles: [`
    body {
      overflow: hidden;
    }
  `],
})
export class PresentationComponent implements OnInit {
  public pdfSrc = '../../../assets/docs/Pentview-presentation.pdf';
  public isHideReceipt: boolean;

  constructor(private http: HttpClient) {}

  downloadPdf() {
    window.open(this.pdfSrc);
  }

  ngOnInit() {
    this.downloadPdf();
  }
}
