<!-- Section 1 -->
<section class="services-section1 padding-section">
	<div class="container-row d-flex row justify-content-between align-items-center">
		<h1 class="text-white title-web t width-section1-title margin-bottom-80 p-0" [@fadeInDown]="fadeInDown">
			{{ 'SERVICES.crm.section1.titleSection1' | translate }}
		</h1>
		<div class="col-12 p-0" [@fadeInDown]="fadeInDown">
			<owl-carousel-o [options]="customOptions" (translated)="getPassedData($event)">
				<ng-container *ngFor="let slide of slides">
					<ng-template carouselSlide [id]="slide.title">
						<img
							(click)="
								zoomImage('image-to-hover-' + slide.title, 'modal-zoom-image-' + slide.title, 'zoom-image-' + slide.title)
							"
							[alt]="slide.alt"
							[id]="'image-to-hover-' + slide.title"
							[ngSrc]="slide.urlImage"
							[title]="slide.title"
							class="carousel-image image-to-hover"
							width="550"
							height="349"
							priority
						/>
					</ng-template>
				</ng-container>
			</owl-carousel-o>
		</div>
		<ng-container *ngFor="let slide of slides">
			<div
				(click)="zoomOutImage('modal-zoom-image-' + slide.title, 'zoom-image-' + slide.title)"
				class="modal-zoom-image"
				[id]="'modal-zoom-image-' + slide.title">
				<img class="zoom-image p-0 p-lg-5" [id]="'zoom-image-' + slide.title" [alt]="slide.alt" />
			</div>
		</ng-container>
	</div>
</section>
<!-- Section 2 - Get your 7 days free trial -->
<section class="padding-section form" data-aos="fade-down">
	<div class="container-row d-flex justify-content-center align-items-center">
		<app-form [fieldsNumber]="2" [openModal]="false" [section]="nameComponent"></app-form>
	</div>
</section>
<!-- Section 3 - CRM performance -->
<section class="services-section3 padding-section overflow-hidden" [style.height]="widthValue > 992 && heightValue">
	<div class="container-row d-flex row justify-content-between align-items-center mx-auto mx-sm-auto px-0">
		<div class="image-cell-container col-12 col-lg-5 mt-4 mt-lg-0 p-0" data-aos="fade-down">
			<img ngSrc="../../../assets/images/services/img-section3-1.webp" width="557" height="616" 
			alt="Mobile app for easier access from anywhere with our systems" priority />
		</div>
		<div class="cards-container col-12 col-lg-7 p-0">
			<div class="row m-0">
				<div class="col-lg col-md col-sm-12 col-12 me-lg-3 me-md-3 me-sm-0 me-0 px-0">
					<div class="card transparent-card mb-4">
						<div class="card-header">
							<h5>
								{{ 'SERVICES.crm.section3.headerCards.subtitle1' | translate }}
							</h5>
						</div>
						<div class="card-body">
							<p class="card-text">
								{{ 'SERVICES.crm.section3.bodyCards.textCard1-1' | translate }}
							</p>
							<p class="card-text">
								{{ 'SERVICES.crm.section3.bodyCards.textCard1-2' | translate }}
							</p>
						</div>
					</div>
					<div class="card transparent-card">
						<div class="card-header">
							<h5>
								{{ 'SERVICES.crm.section3.headerCards.subtitle3' | translate }}
							</h5>
						</div>
						<div class="card-body">
							<p class="card-text">
								{{ 'SERVICES.crm.section3.bodyCards.textCard3-1' | translate }}
							</p>
							<p class="card-text">
								{{ 'SERVICES.crm.section3.bodyCards.textCard3-2' | translate }}
							</p>
						</div>
					</div>
				</div>

				<div class="col-lg col-md col-sm-12 col-12 ms-ml-3 ms-md-3 ms-sm-0 ms-0 mt-lg-5 mt-md-5 mt-sm-3 mt-3 px-0">
					<div class="card transparent-card mb-lg-4 mb-md-4 mb-sm-0 mb-0">
						<div class="card-header">
							<h5>
								{{ 'SERVICES.crm.section3.headerCards.subtitle2' | translate }}
							</h5>
						</div>
						<div class="card-body">
							<p class="card-text">
								{{ 'SERVICES.crm.section3.bodyCards.textCard2-1' | translate }}
							</p>
							<p class="card-text">
								{{ 'SERVICES.crm.section3.bodyCards.textCard2-2' | translate }}
							</p>
						</div>
					</div>
					<div class="card transparent-card mt-sm-3 mt-3">
						<div class="card-header">
							<h5>
								{{ 'SERVICES.crm.section3.headerCards.subtitle4' | translate }}
							</h5>
						</div>
						<div class="card-body">
							<p class="card-text">
								{{ 'SERVICES.crm.section3.bodyCards.textCard4-1' | translate }}
							</p>
							<p class="card-text">
								{{ 'SERVICES.crm.section3.bodyCards.textCard4-2' | translate }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
