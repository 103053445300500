import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { fadeInDown } from 'ng-animate';
import { MetaTagsService } from 'src/app/services/metaTags.service';
@Component({
  selector: "app-contacts-us",
  templateUrl: "./contacts-us.component.html",
  styleUrls: ["./contacts-us.component.css"],
  animations: [
    trigger("fadeInDown", [transition("* => *", useAnimation(fadeInDown))]),
  ],
})
export class ContactsUsComponent {
  nameComponent: string = "contacts";
  @ViewChild("videoContact") videoContact!: ElementRef;
  public isVideoVisible = true;
  public fadeInDown: any;
  @HostListener("window:scroll") checkVideoVisibility() {
    const rect1 = this.videoContact.nativeElement.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    this.isVideoVisible = rect1.top < windowHeight && rect1.bottom >= 0;
  }

  constructor(private metaTagsService: MetaTagsService) {}

  ngOnInit() {
    const title = "Contacts us";
    const description =
      "";
    const keywords =
      "";
    this.metaTagsService.updateTags(title, description, keywords);
  }
}
