<nav class="navbar navbar-expand-lg p-0">
	<a routerLink="/webtrader-and-crm-system-for-you-brokerage">
		<img src="../../../assets/images/logo.svg" alt="Logo" />
	</a>
	<button
		class="navbar-toggler"
		title="toggle"
		data-bs-toggle="collapse"
		data-bs-toggle="offcanvas"
		data-bs-target="#offcanvasScrolling"
		(click)="showToggleNavigation()"
		[attr.aria-expanded]="!isNavbarCollapsed ? 'true' : 'false'"
		aria-controls="navbarNav">
		
		<span class="navbar-toggler-icon" (click)="isMobile() && toggleOffcanvas()"></span>
	</button>
	<div
		class="offcanvas offcanvas-end w-100"
		data-bs-scroll="true"
		data-bs-backdrop="false"
		tabindex="-1"
		id="offcanvasScrolling"
		aria-labelledby="offcanvasScrollingLabel">
		<div class="offcanvas-header justify-content-end">
			<div data-bs-theme="dark">
				<button title="toggle" class="btn-close pt-5 pr-5" data-bs-dismiss="offcanvas" aria-label="Close" (click)="isMobile() && toggleOffcanvas()"></button>
			</div>
		</div>
		<div class="offcanvas-body justify-content-end align-items-center">
			<ul class="navbar-nav">
				<li class="nav-item d-block d-lg-none pb-3 pb-lg-0">
					<a routerLink="/webtrader-and-crm-system-for-you-brokerage" routerLinkActive="active" class="nav-link" data-bs-dismiss="offcanvas" (click)="isMobile() && toggleOffcanvas()">{{
						'SHARED.header.home' | translate
					}}</a>
				</li>
				<li class="nav-item pb-3 pb-lg-0">
					<div class="dropdown">
						<button 
							class="btn dropdown-toggle text-white"
							title="dropdown" 
							data-bs-toggle="dropdown" 
							aria-expanded="false">
							{{ 'SHARED.header.services' | translate }}
						</button>

						<ul class="dropdown-menu">
							<li>
								<a
									class="dropdown-item"
									routerLink="/services/empower-your-connections-with-pentviews-crm-system"
									routerLinkActive="active"
									data-bs-dismiss="offcanvas"
									(click)="isMobile() && toggleOffcanvas()"
									>{{ 'SHARED.header.crmSystem' | translate }}</a
									
								>
							</li>
							<li>
								<a
									class="dropdown-item"
									routerLink="/services/optimize-your-brokerage-with-pentviews-webtrader"
									routerLinkActive="active"
									data-bs-dismiss="offcanvas"
									(click)="isMobile() && toggleOffcanvas()"
									>{{ 'SHARED.header.webTrader' | translate }}</a
								>
							</li>
							<li>
								<a
									class="dropdown-item"
									routerLink="/services/additional-services-for-your-brokerage"
									routerLinkActive="active"
									data-bs-dismiss="offcanvas"
									(click)="isMobile() && toggleOffcanvas()"
									>{{ 'SHARED.header.additionalServices' | translate }}</a
								>
							</li>
						</ul>
					</div>
				</li>
				<li class="nav-item pb-3 pb-lg-0 px-lg-2">
					<a routerLink="/about-us" routerLinkActive="active" class="nav-link" data-bs-dismiss="offcanvas" (click)="isMobile() && toggleOffcanvas()">{{
						'SHARED.header.aboutUs' | translate
					}}</a>
				</li>
				<li class="nav-item pb-3 pb-lg-0 pe-4 py-0">
					<a routerLink="/contacts-us" routerLinkActive="active" class="nav-link" data-bs-dismiss="offcanvas" (click)="isMobile() && toggleOffcanvas()">{{
						'SHARED.header.contactsUs' | translate
					}}</a>
				</li>
				<li class="nav-item d-none d-lg-block ps-5 pe-4 py-0">
					<button title="Open" class="get-trial-button d-none d-lg-block" (click)="openRegisterFormModal()">
						{{ 'TEXTBUTTONS.getTrial' | translate }}
					</button>
				</li>
				<li class="nav-item ps-2 pe-0 py-0">
					<div dropdown class="dropdown btn-group w-100">
						<button
							class="dropdown-language dropdown-tootgle row d-flex justify-content-between align-items-center mx-0 dropdown-max-content"
							title="dropdown"
							dropdownToggle
							style="width: max-content">
							<div class="col-auto text-white pe-0">
								{{ selectedLanguage.name! }}
							</div>
							<div class="dropdown-flag col-auto px-2">
								<img [src]="selectedLanguage.flagUrl!" alt="language country flag" width="100" height="60" />
							</div>
						</button>
						<ul *dropdownMenu class="menu-list dropdown-menu dropdown-max-content" role="menu">
							<li class="menu-item pe-0" role="menuitem" *ngFor="let lan of languagesList" (click)="changeLanguage(lan)">
								<span class="dropdown-item item cursor-pointer text-white d-flex justify-content-between align-items-cnter">
									{{ lan.name }}
									<img [src]="lan.flagUrl" alt="language country flag" width="100" height="60" class="ms-2" />
								</span>
							</li>
						</ul>
					</div>
				</li>
			</ul>
		</div>
	</div>
</nav>
