import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import * as AOS from 'aos';
import { fadeInDown } from 'ng-animate';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { MetaTagsService } from 'src/app/services/metaTags.service';
@Component({
	selector: 'app-web-trader',
	templateUrl: './web-trader.component.html',
	styleUrls: ['./web-trader.component.css'],
	animations: [trigger('fadeInDown', [transition('* => *', useAnimation(fadeInDown))])],
})
export class WebTraderComponent {
	@ViewChild('videoWebTraderSection3') videoWebTraderSection3!: ElementRef;
	public fadeInDown: any;
	public nameComponent: string;
	public heightValue: string;
	public widthValue: number;
	public isVideoVisible = false;
	public customOptions: OwlOptions = {
		loop: true,
		rewind: false,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: false,
		autoplay: false,
		autoplayTimeout: 0,
		autoplayHoverPause: false,
		dots: false,
		nav: true, // Habilitar las flechas de navegación
		navText: [
			'<img src="../../../assets/images/services/free-icon-left.svg" width="50" height="50" alt="row left">',
			'<img src="../../../assets/images/services/free-icon-right.svg" width="50" height="50" alt="row right">',
		], // Personalizar el contenido de las flechas (puedes utilizar iconos Font Awesome u otros)

		responsive: {
			0: {
				items: 1,
				stagePadding: 10,
				autoWidth: true,
			},
			576: {
				items: 1.5,
				stagePadding: 20,
				autoWidth: false,
			},

			768: {
				items: 1.75,
				stagePadding: 20,
				autoWidth: true,
			},
			992: {
				items: 2.25,
				stagePadding: 100,
				autoWidth: true,
			},
			1366: {
				items: 2.25,
				stagePadding: 100,
				autoWidth: true,
			},
		},
	};
	public activeSlides: SlidesOutputData;
	public slides = [
		{
			title: 'slide1',
			alt: 'Simple and easy-to-understand WebTrader for new clients allows market research',
			urlImage: '../../../assets/images/services/webtrader-section1-slide1.webp',
		},
		{
			title: 'slide2',
			alt: 'Creation and establishment of personal data for better use of the WebTrader in Pentview',
			urlImage: '../../../assets/images/services/webtrader-section1-slide2.webp',
		},
		{
			title: 'slide3',
			alt: 'Multiple charts on products of our systems for better market analysis',
			urlImage: '../../../assets/images/services/webtrader-section1-slide3.webp',
		},
		{
			title: 'slide4',
			alt: 'Comparative charts of a product in multiple time ranges or with various filters',
			urlImage: '../../../assets/images/services/webtrader-section1-slide4.webp',
		},
	];

	constructor(
		private metaTagsService: MetaTagsService
	  ) {}

	ngOnInit() {
		this.onWindowSize();
		this.nameComponent = 'crm';
		AOS.init();
		const title = "Optimize your brokerage with Penview’s WebTrader";
    const description = "Discover Pentview's WebTrader, where CRM meets user-friendly simplicity. Empower your traders with pro tools for effortless portfolio control.";
    const keywords = "effortless WebTrader analytics, Intuitive WebTrader Interface, WebTrader CRM integration insights, user-friendly WebTrader interface, WebTrader for user-friendly analytics";
    this.metaTagsService.updateTags(title, description, keywords);
	}

	@HostListener('window:resize', ['$event'])
	private onWindowSize(): void {
		this.calculateTable();
	}

	@HostListener('window:scroll') checkVideoVisibility() {
		const rectVideo = this.videoWebTraderSection3.nativeElement.getBoundingClientRect();
		const windowHeight = window.innerHeight;

		this.isVideoVisible = rectVideo.top < windowHeight && rectVideo.bottom >= 0;
	}

	private calculateTable(): void {
		const windowHeight = window.innerHeight;
		const tableHeaderHeight = 98;
		const tableHeight = windowHeight - tableHeaderHeight;
		this.heightValue = `${tableHeight}px`;
	}

	public getPassedData(data: SlidesOutputData): void {
		this.activeSlides = data;
	}

	public zoomImage(imageId: string, modalZoomId: string, zoomImageId: string) {
		const imageToHover = document.getElementById(imageId) as HTMLImageElement;
		const modalZoomImage = document.getElementById(modalZoomId) as HTMLElement;
		const zoomImage = document.getElementById(zoomImageId) as HTMLImageElement;

		if (imageToHover) {
			modalZoomImage.style.display = 'block';

			const otherModals = document.querySelectorAll('.modal-zoom-image') as NodeListOf<HTMLElement>;
			otherModals.forEach((otherModal) => {
				if (otherModal.id !== modalZoomId) otherModal.style.display = 'none';
			});
			zoomImage.src = imageToHover.src;
		}
	}

	public zoomOutImage(modalZoomId: string, zoomImageId: string) {
		const modalZoomImage = document.getElementById(modalZoomId) as HTMLElement;
		const zoomImage = document.getElementById(zoomImageId) as HTMLImageElement;

		zoomImage.className += ' out';
		setTimeout(() => {
			modalZoomImage.style.display = 'none';
			zoomImage.className = 'zoom-image';
		}, 400);
	}
}
