<section
	class="section-templates padding-section w-100"
	[ngStyle]="!viewTemplates ? { margin: '135px 0' } : { margin: '0' }">
	<div class="container-row row">
		<ng-container *ngIf="!viewTemplates">
			<form class="row g-2 justify-content-center m-0" [formGroup]="passwordForm" (submit)="access()">
				<div class="form-group col-12 col-md-4 m-0 ps-0 pe-4">
					<label class="form-label text-white">{{ 'TEMPLATES.formAccess.label' | translate }}</label>
					<input
						type="password"
						class="form-control"
						[placeholder]="'TEMPLATES.formAccess.placeholder' | translate"
						formControlName="password" />
					<ng-container
						*ngIf="invalidFields || (passwordForm.controls.password.touched && passwordForm.controls.password.errors)">
						<small class="error-message" *ngIf="passwordForm.controls.password.errors?.required">
							Password is required
						</small>
						<small class="error-message" *ngIf="passwordForm.controls.password.errors?.pattern"> Password invalid </small>
					</ng-container>
				</div>
				<div class="form-group col-12 col-md-auto d-flex justify-content-center mt-md-4 mt-lg-4">
					<button class="get-trial-button text-white">{{ 'TEXTBUTTONS.access' | translate }}</button>
				</div>
			</form>
		</ng-container>
		<ng-container *ngIf="viewTemplates">
			<div class="col-12 p-0">
				<h1 class="text-center text-white title-templates" style="margin-bottom: 1.875rem">
					{{ 'TEMPLATES.hiWereAre' | translate }} <span class="text-blue">{{ 'TEMPLATES.pentview' | translate }}</span
					>!
				</h1>
				<p class="text-center text-white main-text-templates">
					{{ 'TEMPLATES.mainText' | translate }}
				</p>
			</div>
			<div class="col-12 margin-top-120 p-0">
				<div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 m-0">
					<div class="col">
						<p class="text-white text-template d-md-block d-lg-none">{{ 'TEMPLATES.template1' | translate }}</p>
						<div class="card">
							<img
								src="../../../assets/images/img-landing-1.svg"
								class="card-img-top"
								[alt]="'TEMPLATES.template1' | translate" />
							<div class="overlay">
								<div class="hidden-text">
									<h3 class="text-black d-none d-lg-block">{{ 'TEMPLATES.template1' | translate }}</h3>
									<a
										href="https://exmerdevelopers.gitlab.io/website-01/"
										target="_blank"
										class="text-black d-none d-lg-block m-0 p-0"
										>{{ 'TEMPLATES.hoverTextWeb' | translate }}
										<img class="p-0 m-0" src="../../../assets/images/location-exit.svg" alt=""
									/></a>
									<a
										href="https://exmerdevelopers.gitlab.io/website-01/"
										target="_blank"
										class="text-black text-center d-md-block d-lg-none m-0 p-0"
										>{{ 'TEMPLATES.hoverTextPhone' | translate }}<br />
										<img class="p-0 m-0" src="../../../assets/images/location-exit.svg" alt=""
									/></a>
								</div>
							</div>
						</div>
					</div>

					<div class="col">
						<p class="text-white text-template d-md-block d-lg-none">{{ 'TEMPLATES.template2' | translate }}</p>
						<div class="card">
							<img src="../../../assets/images/img-landing-2.svg" class="card-img-top" alt="Template 2" />
							<div class="overlay">
								<div class="hidden-text">
									<h3 class="text-black d-none d-lg-block">{{ 'TEMPLATES.template2' | translate }}</h3>
									<a
										href="https://exmerdevelopers.gitlab.io/website-2/"
										target="_blank"
										class="text-black d-none d-lg-block m-0 p-0"
										>{{ 'TEMPLATES.hoverTextWeb' | translate }}
										<img class="p-0 m-0" src="../../../assets/images/location-exit.svg" alt=""
									/></a>
									<a
										href="https://exmerdevelopers.gitlab.io/website-2/"
										target="_blank"
										class="text-black text-center d-md-block d-lg-none m-0 p-0"
										>{{ 'TEMPLATES.hoverTextPhone' | translate }}<br />
										<img class="p-0 m-0" src="../../../assets/images/location-exit.svg" alt=""
									/></a>
								</div>
							</div>
						</div>
					</div>

					<div class="col">
						<p class="text-white text-template d-md-block d-lg-none">{{ 'TEMPLATES.template3' | translate }}</p>
						<div class="card">
							<img src="../../../assets/images/img-landing-3.svg" class="card-img-top" alt="Template 3" />
							<div class="overlay">
								<div class="hidden-text">
									<h3 class="text-black d-none d-lg-block">{{ 'TEMPLATES.template3' | translate }}</h3>
									<a
										href="https://exmerdevelopers.gitlab.io/website-3/"
										target="_blank"
										class="text-black d-none d-lg-block m-0 p-0"
										>{{ 'TEMPLATES.hoverTextWeb' | translate }}
										<img class="p-0 m-0" src="../../../assets/images/location-exit.svg" alt=""
									/></a>
									<a
										href="https://exmerdevelopers.gitlab.io/website-3/"
										target="_blank"
										class="text-black text-center d-md-block d-lg-none m-0 p-0"
										>{{ 'TEMPLATES.hoverTextPhone' | translate }}<br />
										<img class="p-0 m-0" src="../../../assets/images/location-exit.svg" alt=""
									/></a>
								</div>
							</div>
						</div>
					</div>

					<div class="col">
						<p class="text-white text-template d-md-block d-lg-none">{{ 'TEMPLATES.template4' | translate }}</p>
						<div class="card">
							<img src="../../../assets/images/img-landing-4.svg" class="card-img-top" alt="Template 4" />
							<div class="overlay">
								<div class="hidden-text">
									<h3 class="text-black d-none d-lg-block">{{ 'TEMPLATES.template4' | translate }}</h3>
									<a
										href="https://exmerdevelopers.gitlab.io/website-4/"
										target="_blank"
										class="text-black d-none d-lg-block m-0 p-0"
										>{{ 'TEMPLATES.hoverTextWeb' | translate }}
										<img class="p-0 m-0" src="../../../assets/images/location-exit.svg" alt=""
									/></a>
									<a
										href="https://exmerdevelopers.gitlab.io/website-4/"
										target="_blank"
										class="text-black text-center d-md-block d-lg-none m-0 p-0"
										>{{ 'TEMPLATES.hoverTextPhone' | translate }}<br />
										<img class="p-0 m-0" src="../../../assets/images/location-exit.svg" alt=""
									/></a>
								</div>
							</div>
						</div>
					</div>

					<div class="col">
						<p class="text-white text-template d-md-block d-lg-none">{{ 'TEMPLATES.template5' | translate }}</p>
						<div class="card">
							<img src="../../../assets/images/img-landing-5.svg" class="card-img-top" alt="Template 5" />
							<div class="overlay">
								<div class="hidden-text">
									<h3 class="text-black d-none d-lg-block">{{ 'TEMPLATES.template5' | translate }}</h3>
									<a
										href="https://exmerdevelopers.gitlab.io/website-5/"
										target="_blank"
										class="text-black d-none d-lg-block m-0 p-0"
										>{{ 'TEMPLATES.hoverTextWeb' | translate }}
										<img class="p-0 m-0" src="../../../assets/images/location-exit.svg" alt=""
									/></a>
									<a
										href="https://exmerdevelopers.gitlab.io/website-5/"
										target="_blank"
										class="text-black text-center d-md-block d-lg-none m-0 p-0"
										>{{ 'TEMPLATES.hoverTextPhone' | translate }}<br />
										<img class="p-0 m-0" src="../../../assets/images/location-exit.svg" alt=""
									/></a>
								</div>
							</div>
						</div>
					</div>

					<div class="col">
						<p class="text-white text-template d-md-block d-lg-none">{{ 'TEMPLATES.template6' | translate }}</p>
						<div class="card">
							<img src="../../../assets/images/img-landing-6.svg" class="card-img-top" alt="Template 6" />
							<div class="overlay">
								<div class="hidden-text">
									<h3 class="text-black d-none d-lg-block">{{ 'TEMPLATES.template6' | translate }}</h3>
									<a
										href="https://exmerdevelopers.gitlab.io/website-6/"
										target="_blank"
										class="text-black d-none d-lg-block m-0 p-0"
										>{{ 'TEMPLATES.hoverTextWeb' | translate }}
										<img class="p-0 m-0" src="../../../assets/images/location-exit.svg" alt=""
									/></a>
									<a
										href="https://exmerdevelopers.gitlab.io/website-6/"
										target="_blank"
										class="text-black text-center d-md-block d-lg-none m-0 p-0"
										>{{ 'TEMPLATES.hoverTextPhone' | translate }}<br />
										<img class="p-0 m-0" src="../../../assets/images/location-exit.svg" alt=""
									/></a>
								</div>
							</div>
						</div>
					</div>

					<div class="col">
						<p class="text-white text-template d-md-block d-lg-none">{{ 'TEMPLATES.template7' | translate }}</p>
						<div class="card">
							<img src="../../../assets/images/img-landing-7.svg" class="card-img-top" alt="Template 7" />
							<div class="overlay">
								<div class="hidden-text">
									<h3 class="text-black d-none d-lg-block">{{ 'TEMPLATES.template7' | translate }}</h3>
									<a
										href="https://exmerdevelopers.gitlab.io/website-7/"
										target="_blank"
										class="text-black d-none d-lg-block m-0 p-0"
										>{{ 'TEMPLATES.hoverTextWeb' | translate }}
										<img class="p-0 m-0" src="../../../assets/images/location-exit.svg" alt=""
									/></a>
									<a
										href="https://exmerdevelopers.gitlab.io/website-7/"
										target="_blank"
										class="text-black text-center d-md-block d-lg-none m-0 p-0"
										>{{ 'TEMPLATES.hoverTextPhone' | translate }}<br />
										<img class="p-0 m-0" src="../../../assets/images/location-exit.svg" alt=""
									/></a>
								</div>
							</div>
						</div>
					</div>

					<div class="col">
						<p class="text-white text-template d-md-block d-lg-none">{{ 'TEMPLATES.template8' | translate }}</p>
						<div class="card">
							<img src="../../../assets/images/img-landing-8.svg" class="card-img-top" alt="Template 8" />
							<div class="overlay">
								<div class="hidden-text">
									<h3 class="text-black d-none d-lg-block">{{ 'TEMPLATES.template8' | translate }}</h3>
									<a
										href="https://exmerdevelopers.gitlab.io/website-8/"
										target="_blank"
										class="text-black d-none d-lg-block m-0 p-0"
										>{{ 'TEMPLATES.hoverTextWeb' | translate }}
										<img class="p-0 m-0" src="../../../assets/images/location-exit.svg" alt=""
									/></a>
									<a
										href="https://exmerdevelopers.gitlab.io/website-8/"
										target="_blank"
										class="text-black text-center d-md-block d-lg-none m-0 p-0"
										>{{ 'TEMPLATES.hoverTextPhone' | translate }}<br />
										<img class="p-0 m-0" src="../../../assets/images/location-exit.svg" alt=""
									/></a>
								</div>
							</div>
						</div>
					</div>

					<div class="col">
						<p class="text-white text-template d-md-block d-lg-none">{{ 'TEMPLATES.template9' | translate }}</p>
						<div class="card">
							<img src="../../../assets/images/img-landing-9.svg" class="card-img-top" alt="Template 9" />
							<div class="overlay">
								<div class="hidden-text">
									<h3 class="text-black d-none d-lg-block">{{ 'TEMPLATES.template9' | translate }}</h3>
									<a
										href="https://exmerdevelopers.gitlab.io/website-9/"
										target="_blank"
										class="text-black d-none d-lg-block m-0 p-0"
										>{{ 'TEMPLATES.hoverTextWeb' | translate }}
										<img class="p-0 m-0" src="../../../assets/images/location-exit.svg" alt=""
									/></a>
									<a
										href="https://exmerdevelopers.gitlab.io/website-9/"
										target="_blank"
										class="text-black text-center d-md-block d-lg-none m-0 p-0"
										>{{ 'TEMPLATES.hoverTextPhone' | translate }}<br />
										<img class="p-0 m-0" src="../../../assets/images/location-exit.svg" alt=""
									/></a>
								</div>
							</div>
						</div>
					</div>

					<div class="col">
						<p class="text-white text-template d-md-block d-lg-none">{{ 'TEMPLATES.template10' | translate }}</p>
						<div class="card">
							<img src="../../../assets/images/img-landing-10.svg" class="card-img-top" alt="Template 10" />
							<div class="overlay">
								<div class="hidden-text">
									<h3 class="text-black d-none d-lg-block">{{ 'TEMPLATES.template10' | translate }}</h3>
									<a
										href="https://exmerdevelopers.gitlab.io/website-10/"
										target="_blank"
										class="text-black d-none d-lg-block m-0 p-0"
										>{{ 'TEMPLATES.hoverTextWeb' | translate }}
										<img class="p-0 m-0" src="../../../assets/images/location-exit.svg" alt=""
									/></a>
									<a
										href="https://exmerdevelopers.gitlab.io/website-10/"
										target="_blank"
										class="text-black text-center d-md-block d-lg-none m-0 p-0"
										>{{ 'TEMPLATES.hoverTextPhone' | translate }}<br />
										<img class="p-0 m-0" src="../../../assets/images/location-exit.svg" alt=""
									/></a>
								</div>
							</div>
						</div>
					</div>

					<div class="col">
						<p class="text-white text-template d-md-block d-lg-none">{{ 'TEMPLATES.template11' | translate }}</p>
						<div class="card">
							<img src="../../../assets/images/img-landing-11.svg" class="card-img-top" alt="Template 11" />
							<div class="overlay">
								<div class="hidden-text">
									<h3 class="text-black d-none d-lg-block">{{ 'TEMPLATES.template11' | translate }}</h3>
									<a
										href="https://exmerdevelopers.gitlab.io/website-11/"
										target="_blank"
										class="text-black d-none d-lg-block m-0 p-0"
										>{{ 'TEMPLATES.hoverTextWeb' | translate }}
										<img class="p-0 m-0" src="../../../assets/images/location-exit.svg" alt=""
									/></a>
									<a
										href="https://exmerdevelopers.gitlab.io/website-11/"
										target="_blank"
										class="text-black text-center d-md-block d-lg-none m-0 p-0"
										>{{ 'TEMPLATES.hoverTextPhone' | translate }}<br />
										<img class="p-0 m-0" src="../../../assets/images/location-exit.svg" alt=""
									/></a>
								</div>
							</div>
						</div>
					</div>

					<div class="col">
						<p class="text-white text-template d-md-block d-lg-none">{{ 'TEMPLATES.template12' | translate }}</p>
						<div class="card">
							<img src="../../../assets/images/img-landing-12.svg" class="card-img-top" alt="Template 12" />
							<div class="overlay">
								<div class="hidden-text">
									<h3 class="text-black d-none d-lg-block">{{ 'TEMPLATES.template12' | translate }}</h3>
									<a
										href="https://exmerdevelopers.gitlab.io/website-12/"
										target="_blank"
										class="text-black d-none d-lg-block m-0 p-0"
										>{{ 'TEMPLATES.hoverTextWeb' | translate }}
										<img class="p-0 m-0" src="../../../assets/images/location-exit.svg" alt=""
									/></a>
									<a
										href="https://exmerdevelopers.gitlab.io/website-12/"
										target="_blank"
										class="text-black text-center d-md-block d-lg-none m-0 p-0"
										>{{ 'TEMPLATES.hoverTextPhone' | translate }}<br />
										<img class="p-0 m-0" src="../../../assets/images/location-exit.svg" alt=""
									/></a>
								</div>
							</div>
						</div>
					</div>

					<div class="col">
						<p class="text-white text-template d-md-block d-lg-none">{{ 'TEMPLATES.template13' | translate }}</p>
						<div class="card">
							<img src="../../../assets/images/img-landing-13.svg" class="card-img-top" alt="Template 13" />
							<div class="overlay">
								<div class="hidden-text">
									<h3 class="text-black d-none d-lg-block">{{ 'TEMPLATES.template13' | translate }}</h3>
									<a
										href="https://exmerdevelopers.gitlab.io/website-13/"
										target="_blank"
										class="text-black d-none d-lg-block m-0 p-0"
										>{{ 'TEMPLATES.hoverTextWeb' | translate }}
										<img class="p-0 m-0" src="../../../assets/images/location-exit.svg" alt=""
									/></a>
									<a
										href="https://exmerdevelopers.gitlab.io/website-13/"
										target="_blank"
										class="text-black text-center d-md-block d-lg-none m-0 p-0"
										>{{ 'TEMPLATES.hoverTextPhone' | translate }}<br />
										<img class="p-0 m-0" src="../../../assets/images/location-exit.svg" alt=""
									/></a>
								</div>
							</div>
						</div>
					</div>

					<div class="col">
						<p class="text-white text-template d-md-block d-lg-none">{{ 'TEMPLATES.template14' | translate }}</p>
						<div class="card">
							<img src="../../../assets/images/img-landing-14.svg" class="card-img-top" alt="Template 14" />
							<div class="overlay">
								<div class="hidden-text">
									<h3 class="text-black d-none d-lg-block">{{ 'TEMPLATES.template14' | translate }}</h3>
									<a
										href="https://exmerdevelopers.gitlab.io/website-14/"
										target="_blank"
										class="text-black d-none d-lg-block m-0 p-0"
										>{{ 'TEMPLATES.hoverTextWeb' | translate }}
										<img class="p-0 m-0" src="../../../assets/images/location-exit.svg" alt=""
									/></a>
									<a
										href="https://exmerdevelopers.gitlab.io/website-14/"
										target="_blank"
										class="text-black text-center d-md-block d-lg-none m-0 p-0"
										>{{ 'TEMPLATES.hoverTextPhone' | translate }}<br />
										<img class="p-0 m-0" src="../../../assets/images/location-exit.svg" alt=""
									/></a>
								</div>
							</div>
						</div>
					</div>

					<!-- <div class="col">
						<p class="text-white text-template d-md-block d-lg-none">{{ 'TEMPLATES.template15' | translate }}</p>
						<div class="card">
							<img src="../../../assets/images/img-landing-15.svg" class="card-img-top" alt="Template 15" />
							<div class="overlay">
								<div class="hidden-text">
									<h3 class="text-black d-none d-lg-block">{{ 'TEMPLATES.template15' | translate }}</h3>
									<a
										href="https://exmerdevelopers.gitlab.io/website-15/"
										target="_blank"
										class="text-black d-none d-lg-block m-0 p-0"
										>{{ 'TEMPLATES.hoverTextWeb' | translate }}
										<img class="p-0 m-0" src="../../../assets/images/location-exit.svg" alt=""
									/></a>
									<a
										href="https://exmerdevelopers.gitlab.io/website-15/"
										target="_blank"
										class="text-black text-center d-md-block d-lg-none m-0 p-0"
										>{{ 'TEMPLATES.hoverTextPhone' | translate }}<br />
										<img class="p-0 m-0" src="../../../assets/images/location-exit.svg" alt=""
									/></a>
								</div>
							</div>
						</div>
					</div> -->
				</div>
			</div>
		</ng-container>
	</div>
</section>
