import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, HostListener } from '@angular/core';
import * as AOS from 'aos';
import { fadeInDown } from 'ng-animate';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { MetaTagsService } from 'src/app/services/metaTags.service';
@Component({
	selector: 'app-crm-system',
	templateUrl: './crm-system.component.html',
	styleUrls: ['./crm-system.component.css'],
	animations: [trigger('fadeInDown', [transition('* => *', useAnimation(fadeInDown))])],
})
export class CrmSystemComponent {
	public fadeInDown: any;
	nameComponent: string;
	heightValue: string;
	widthValue: number;
	customOptions: OwlOptions = {
		loop: true,
		rewind: false,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: false,
		autoplay: false,
		autoplayTimeout: 0,
		autoplayHoverPause: false,
		dots: false,
		nav: true, // Enable navigation arrows
		navText: [
			'<img src="../../../assets/images/services/free-icon-left.svg" width="50" height="50" alt="arrow left">',
			'<img src="../../../assets/images/services/free-icon-right.svg" width="50" height="50" alt="arrow right">',
		],

		responsive: {
			0: {
				items: 1.1,
				stagePadding: 10,
				autoWidth: true,
			},
			576: {
				items: 1.25,
				stagePadding: 20,
				autoWidth: false,
			},
			768: {
				items: 1.5,
				stagePadding: 20,
				autoWidth: true,
			},
			992: {
				items: 2.25,
				stagePadding: 100,
				autoWidth: true,
			},
			1366: {
				items: 3.25,
				stagePadding: 100,
				autoWidth: true,
			},
		},
	};
	activeSlides: SlidesOutputData;
	slides = [
		{
			title: 'slide1',
			alt: 'Different charts that offer a better perspective of sales with more specific analysis',
			urlImage: '../../../assets/images/services/crm-section1-slide1.webp',
		},
		{
			title: 'slide2',
			alt: 'Comparative tables regarding the internal performance of users',
			urlImage: '../../../assets/images/services/crm-section1-slide2.webp',
		},
		{
			title: 'slide3',
			alt: 'Comparative chart on sales for each team member',
			urlImage: '../../../assets/images/services/crm-section1-slide3.webp',
		},
		{
			title: 'slide4',
			alt: 'Charts regarding the sales evolution of our partners, and broker tools',
			urlImage: '../../../assets/images/services/crm-section1-slide4.webp',
		},
	];

	constructor(
		private metaTagsService: MetaTagsService
	  ) {}

	ngOnInit() {
		this.onWindowSize();
		this.nameComponent = 'crm';
		AOS.init();

		const title = 'Empower Your Connections with Pentview\'\s CRM System';
		const description = 'Improve your workflow with Pentview software, effortlessly tracking tasks, team performance, and sales details. Specifically designed for brokerage services.';
		const keywords = 'Trading-focused CRM, Detailed client needs analysis, User-friendly trading CRM, Business-focused CRM, CRM for detailed client analysis';
		this.metaTagsService.updateTags(title, description, keywords);

	}

	@HostListener('window:resize', ['$event'])
	private onWindowSize(): void {
		this.calculateTable();
	}

	private calculateTable(): void {
		const windowHeight = window.innerHeight;
		const tableHeaderHeight = 98;
		const tableHeight = windowHeight - tableHeaderHeight;
		this.heightValue = `${tableHeight}px`;
	}

	public getPassedData(data: SlidesOutputData): void {
		this.activeSlides = data;
	}

	public zoomImage(imageId: string, modalZoomId: string, zoomImageId: string) {
		const imageToHover = document.getElementById(imageId) as HTMLImageElement;
		const modalZoomImage = document.getElementById(modalZoomId) as HTMLElement;
		const zoomImage = document.getElementById(zoomImageId) as HTMLImageElement;

		if (imageToHover) {
			modalZoomImage.style.display = 'block';

			const otherModals = document.querySelectorAll('.modal-zoom-image') as NodeListOf<HTMLElement>;
			otherModals.forEach((otherModal) => {
				if (otherModal.id !== modalZoomId) otherModal.style.display = 'none';
			});
			zoomImage.src = imageToHover.src;
		}
	}

	public zoomOutImage(modalZoomId: string, zoomImageId: string) {
		const modalZoomImage = document.getElementById(modalZoomId) as HTMLElement;
		const zoomImage = document.getElementById(zoomImageId) as HTMLImageElement;

		zoomImage.className += ' out';
		setTimeout(() => {
			modalZoomImage.style.display = 'none';
			zoomImage.className = 'zoom-image';
		}, 400);
	}
}
