import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { AdditionalServicesComponent } from './components/additional-services/additional-services.component';
import { ContactsUsComponent } from './components/contacts-us/contacts-us.component';
import { CrmSystemComponent } from './components/crm-system/crm-system.component';
import { PresentationComponent } from './components/presentation/presentation.component';
import { TemplatesComponent } from './components/templates/templates.component';
import { WebTraderComponent } from './components/web-trader/web-trader.component';
import { HomeComponent } from './pages/home/home.component';
import { InstrumentsComponent } from './components/instruments/instruments.component';
import { ErrorComponent } from './components/error/error.component';

const routes: Routes = [
	{ path: '', component: HomeComponent, pathMatch: 'full' },
	{ path: 'webtrader-and-crm-system-for-you-brokerage', redirectTo: '', pathMatch: 'full' },
	{ path: 'about-us', component: AboutUsComponent },
	{ path: 'contacts-us', component: ContactsUsComponent },
	{ path: 'services/empower-your-connections-with-pentviews-crm-system', component: CrmSystemComponent },
	{ path: 'services/optimize-your-brokerage-with-pentviews-webtrader', component: WebTraderComponent },
	{ path: 'services/additional-services-for-your-brokerage', component: AdditionalServicesComponent },
	{ path: 'presentation', component: PresentationComponent },
	{ path: 'templates', component: TemplatesComponent },
	{ path: 'instruments', component: InstrumentsComponent },
	{ path: '**', component: ErrorComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule { }
