<div
  *ngIf="fieldsNumber === 4"
  [ngClass]="
    !openModal && fieldsNumber === 4
      ? 'form-contact-us d-flex justify-content-center align-item-center'
      : 'form-modal'
  "
>
  <div class="modal-header px-2 pb-3" *ngIf="openModal">
    <div class="d-flex row align-items-center w-100">
      <div class="col-12 d-flex justify-content-end px-0 pb-3">
        <button
          title="Close"
          arial-label="Close"
          class="close-form"
          (click)="registerFormModalRef.hide()"
        >
          <img src="../../../assets/images/close-modal.svg" alt="close modal" width="30" height="30" />
        </button>
      </div>
      <div class="col-12 px-0">
        <h3 class="text-white text-center title-modal">
          {{ "SHARED.form.responseModal.titleModal" | translate }}
        </h3>
        <p class="text-center text-modal-header">
          {{ "SHARED.form.responseModal.textModalHeader" | translate }}
        </p>
      </div>
    </div>
  </div>
  <div class="modal-body" [ngClass]="openModal ? 'px-5 pb-5' : 'px-0'">
    <form [formGroup]="registerForm" (ngSubmit)="registerClient()">
      <!-- [ngClass]="openModal ? '' : 'm-0 p-0 mx-lg-5 px-lg-5'" -->
      <div
        class="row align-items-start"
        [ngClass]="openModal ? 'g-1' : ' mx-lg-5 px-lg-5 '"
      >
        <div
          [ngClass]="
            openModal ? 'form-group col-12' : 'form-group col-md-6 col-sm-12'
          "
        >
          <label class="form-label text-white" *ngIf="openModal">{{
            "HOME.section1.form.labels.name" | translate
          }}</label>
          <label
            class="form-label text-white d-lg-none d-block"
            *ngIf="!openModal"
            >{{ "SHARED.form.labels.firstName" | translate }}</label
          >
          <ng-container *ngIf="!openModal">
            <label
              [ngClass]="
                flagFirstName && !openModal
                  ? 'form-label text-white d-lg-block d-none text-label'
                  : 'text-transparent d-lg-block d-none mt-2'
              "
              >{{ "SHARED.form.labels.name" | translate }}</label
            >
          </ng-container>

          <input
            type="text"
            class="form-control"
            (click)="onKey('firstName')"
            [placeholder]="
              openModal
                ? ('HOME.section1.form.labels.name' | translate)
                : ('SHARED.form.placeholders.firstName' | translate)
            "
            formControlName="firstName"
            (blur)="listenText($event, 'firstName','registerFor')"
          />
          <ng-container
            *ngIf="
              invalidField || (form.firstName.touched && form.firstName.errors)
            "
          >
            <small
              class="error-message"
              *ngIf="form.firstName.errors?.required"
              >{{
                openModal
                  ? ("SHARED.form.validators.nameRequired" | translate)
                  : ("SHARED.form.validators.firstNameRequired" | translate)
              }}</small
            >
            <small
              class="error-message"
              *ngIf="form.firstName.errors?.pattern"
              >{{
                openModal
                  ? ("SHARED.form.validators.nameLatinCharacters" | translate)
                  : ("SHARED.form.validators.firstNameLatinCharacters"
                    | translate)
              }}</small
            >
          </ng-container>
        </div>
        <div class="col-md-6 form-group order-2 order-md-1" *ngIf="!openModal">
          <label class="form-label text-white d-lg-none d-block">{{
            "SHARED.form.labels.secondName" | translate
          }}</label>
          <ng-container *ngIf="!openModal">
            <label
              [ngClass]="
                flagLastName && !openModal
                  ? 'form-label text-white d-lg-block d-none text-label '
                  : 'text-transparent d-lg-block d-none mt-2'
              "
              >{{ "SHARED.form.labels.secondName" | translate }}</label
            >
          </ng-container>
          <input
            type="text"
            class="form-control"
            (click)="onKey('lastName')"
            [placeholder]="'SHARED.form.placeholders.lastName' | translate"
            formControlName="lastName"
            (blur)="listenText($event, 'lastName' ,'registerFor')"
          />
          <ng-container
            *ngIf="
              invalidField || (form.lastName.touched && form.lastName.errors)
            "
          >
            <small
              class="error-message"
              *ngIf="form.lastName.errors?.required"
              >{{
                "SHARED.form.validators.lastNameRequired" | translate
              }}</small
            >
            <small
              class="error-message"
              *ngIf="form.firstName.errors?.pattern"
              >{{
                "SHARED.form.validators.lastNameLatinCharacters" | translate
              }}</small
            >
          </ng-container>
        </div>
      </div>
      <div
        class="row align-items-start"
        [ngClass]="
          openModal ? 'g-1' : 'g-2 m-0 p-0 mx-lg-5 px-lg-5 align-item-start'
        "
      >
        <div
          class="form-group"
          [ngClass]="
            openModal ? 'col-12 order-2' : 'col-12 col-md-6 order-1 order-lg-2'
          "
          *ngIf="fieldsNumber === 4"
        >
          <label class="form-label text-white" *ngIf="openModal">{{
            "SHARED.form.labels.email" | translate
          }}</label>
          <label
            class="form-label text-white d-lg-none d-block"
            *ngIf="!openModal"
            >{{ "SHARED.form.labels.email" | translate }}</label
          >
          <ng-container *ngIf="!openModal">
            <label
              [ngClass]="
                flagEmail && !openModal
                  ? 'form-label text-white d-lg-block d-none text-label'
                  : 'text-transparent d-lg-block d-none mt-2'
              "
              >{{ "SHARED.form.labels.email" | translate }}</label
            >
          </ng-container>
          <input
            type="email"
            (click)="onKey('email')"
            class="form-control"
            [placeholder]="'SHARED.form.placeholders.email' | translate"
            formControlName="email"
            (input)="onEmailInput($event)"
          />
          <ng-container
            *ngIf="invalidField || (form.email.touched && form.email.errors)"
          >
            <small class="error-message" *ngIf="form.email.errors?.required">{{
              "SHARED.form.validators.emailRequired" | translate
            }}</small>
            <small class="error-message" *ngIf="form.email.errors?.pattern"
              >{{ "SHARED.form.validators.emailInvalid1" | translate }}:
              {{ "SHARED.form.validators.emailInvalid2" | translate }}</small
            >
          </ng-container>
        </div>
        <div
          class="form-group"
          [ngClass]="
            openModal ? 'col-12 order-1' : 'col-12 col-md-6 order-3 order-lg-3'
          "
        >
          <label class="form-label text-white" *ngIf="openModal">{{
            "SHARED.form.labels.phoneNumber" | translate
          }}</label>
          <label
            class="form-label text-white d-lg-none d-block"
            *ngIf="!openModal"
            >{{ "SHARED.form.labels.phoneNumber" | translate }}</label
          >
          <ng-container *ngIf="!openModal">
            <label
              [ngClass]="
                flagPhone && !openModal
                  ? 'form-label text-white d-lg-block d-none text-label'
                  : 'text-transparent d-lg-block d-none mt-2'
              "
              >{{ "SHARED.form.labels.phoneNumber" | translate }}</label
            >
          </ng-container>
          <input
            class="form-control input-phone"
            type="text"
            (click)="onKey('phone')"
            formControlName="phone"
            ng2TelInput
            [ng2TelInputOptions]="{
              preferredCountries: ['us', 'gb'],
              separateDialCode: true,
              autoHideDialCode: false,
              nationalMode: false,
              formatOnInit: false,
              enablePlaceholder: true,
              inputOptions: { autocomplete: 'on' },
              enableSearch: true,
              countryCode: true,
              countryName: true,
              countryFlag: true,
              multiSelect: false
            }"
            (intlTelInputObject)="telInputObject($event)"
            (countryChange)="onCountryChange($event)"
            (keypress)="validateNumber($event)"
          />
          <ng-container
            *ngIf="invalidField || (form.phone.touched && form.phone.errors)"
          >
            <small class="error-message" *ngIf="form.phone.errors?.required">{{
              "SHARED.form.validators.phoneRequired" | translate
            }}</small>
            <small class="error-message" *ngIf="form.phone.errors?.pattern">{{
              "SHARED.form.validators.phoneOnlyNumbers" | translate
            }}</small>
            <small class="error-message" *ngIf="form.phone.errors?.maxlength">{{
              "SHARED.form.validators.phoneMaxLength" | translate
            }}</small>
          </ng-container>
        </div>
        <div class="order-4 d-flex justify-content-center">
          <button
            title="Trial"
            type="submit"
            class="get-trial-button"
            [disabled]="isSubmitting"
          >
            {{ "TEXTBUTTONS.getTrial" | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- Modal with response message -->
<ng-template #messageModal>
  <div class="message-modal">
    <div class="modal-header d-flex justify-content-end align-items-center">
      <button
        title="Trial"
        arial-label="Close"
        class="close-form"
        (click)="closeMessageModal()"
      >
        <img src="../../../assets/images/close-modal.svg" alt="close modal" width="30" height="30" />
      </button>
    </div>
    <div class="modal-body">
      <p
        class="text-center text-white text-message"
        style="font-size: 18px; font-weight: 700"
      >
        {{ backMessage }}
      </p>
      <p class="text-center text-white text-message">
        {{ "SHARED.form.responseModal.replyMessage" | translate }}
      </p>
      <div class="col-12 d-flex justify-content-center align-items-center">
        <img src="../../../assets/images/pentview-logo.svg" alt="pentview logo" />
      </div>
    </div>
  </div>
</ng-template>
<div
  *ngIf="fieldsNumber === 2 && section !== 'contacts'"
  [ngClass]="{ 'form-services': section !== 'home' }"
>
  <div *ngIf="section !== 'home'">
    <h2 class="text-32 text-center">
      {{ "SHARED.form.previewTexts.getYour7days" | translate }}
    </h2>
    <p class="mt-5 text-register">
      {{ "SHARED.form.previewTexts.registerToday" | translate }}
    </p>
    <p class="m-0 mb-5 text-bear-mind">
      {{ "SHARED.form.previewTexts.bearInMind" | translate }}
    </p>
  </div>
  <form [formGroup]="registerFormDuo" (ngSubmit)="registerClientDuo()">
    <div [ngClass]="section !== 'home' ? 'row align-items-start' : ''">
      <div
        [ngClass]="
          section !== 'home'
            ? 'form-group col-lg-6 col-md-12 justify-content-lg-end '
            : 'col-md-6 '
        "
      >
        <label class="form-label w-100 m-0 py-2">{{
          "HOME.section1.form.labels.name" | translate
        }}</label>
        <input
          [placeholder]="'HOME.section1.form.placeholders.name' | translate"
          class="form-control form-control-sm"
          type="text"
          formControlName="fullname"
          (blur)="listenText($event, 'fullname','registerFormDuo')"
        />
        <ng-container
          *ngIf="
            invalidField || (formDuo.fullname.touched && formDuo.fullname.errors)
          "
        >
          <small
            class="error-message"
            *ngIf="formDuo.fullname.errors?.required"
            >{{
              openModal
                ? ("SHARED.form.validators.nameRequired" | translate)
                : ("SHARED.form.validators.firstNameRequired" | translate)
            }}</small
          >
          <small class="error-message" *ngIf="formDuo.fullname.errors?.pattern">{{
            openModal
              ? ("SHARED.form.validators.nameLatinCharacters" | translate)
              : ("SHARED.form.validators.firstNameLatinCharacters" | translate)
          }}</small>
        </ng-container>
      </div>
      <div
        [ngClass]="
          section !== 'home' ? 'form-group col-lg-6 col-md-12' : 'col-md-6'
        "
      >
        <label class="form-label w-100 m-0 py-2">{{
          "HOME.section1.form.labels.phoneNumber" | translate
        }}</label>
        <input
          type="text"
          formControlName="celphone"
          class="form-control form-control-sm"
          ng2TelInput
          [ng2TelInputOptions]="{
            autoHideDialCode: false,
            countryCode: true,
            countryFlag: true,
            countryName: true,
            enablePlaceholder: true,
            enableSearch: true,
            formatOnInit: false,
            inputOptions: { autocomplete: 'on' },
            multiSelect: false,
            nationalMode: false,
            preferredCountries: ['us', 'gb'],
            separateDialCode: true
          }"
          (intlTelInputObject)="telInputObject($event)"
          (countryChange)="onCountryChange($event)"
          (keypress)="validateNumber($event)"
        />
        <ng-container
          *ngIf="invalidField || (formDuo.celphone.touched && formDuo.celphone.errors)"
        >
          <small class="error-message" *ngIf="formDuo.celphone.errors?.required">{{
            "SHARED.form.validators.phoneRequired" | translate
          }}</small>
          <small class="error-message" *ngIf="formDuo.celphone.errors?.pattern">{{
            "SHARED.form.validators.phoneOnlyNumbers" | translate
          }}</small>
          <small class="error-message" *ngIf="formDuo.celphone.errors?.maxlength">{{
            "SHARED.form.validators.phoneMaxLength" | translate
          }}</small>
        </ng-container>
      </div>
      <div
        [ngClass]="
          section !== 'home'
            ? 'd-flex justify-content-center col-md-12'
            : 'col-md-6 pt-4'
        "
      >
        <button class="get-trial-button" type="submit" title="Trial">
          {{ "TEXTBUTTONS.getTrial" | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
